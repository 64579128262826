import EventBus from '../components/EventBus'

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}

let evtSource = new EventSource( API_URL + '/api/v1/sse/event ',{ withCredentials: true });
export default {

    openSse: function(){
        evtSource.onmessage = function( event ) {
            if ( event && event.data ){
                let parsedEvent = JSON.parse( event.data );
                if( parsedEvent && parsedEvent !== undefined && parsedEvent.name !== undefined ){
                    EventBus.$emit( parsedEvent.name, parsedEvent.content )
                }
            }
        }
    },
    closeSSe: function(){
        evtSource.close();
    }

}