<template>
    <v-container class="pa-1" >
            <p class="text-right ma-0 pa-1" style="color:grey;">
                {{version}}
            </p>
    </v-container>
</template>

<script>
import MetaApiClient from '../rest-externals/MetaApiClient';

export default {


    data() {
        return {
            version: ""
        }
    },
    created: async function(){
       var response = await MetaApiClient.getVersion();
       this.version = response.data.value
    }
}
</script>