import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"counter":10,"label":"Prénom","readonly":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCol,[_c(VTextField,{attrs:{"counter":10,"label":"Nom","readonly":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"E-mail","readonly":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCol,[(_vm.isPremiumUser('USER_MINER'))?_c('div',[_c(VTextField,{attrs:{"label":"Clé","readonly":""},model:{value:(_vm.appKey),callback:function ($$v) {_vm.appKey=$$v},expression:"appKey"}})],1):_vm._e()])],1),_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,[_c(VTextField,{attrs:{"label":"Investissement (en $)"},model:{value:(_vm.invest),callback:function ($$v) {_vm.invest=$$v},expression:"invest"}})],1),_c(VCol,[(_vm.isPremiumUser('USER_MINER'))?_c(VSwitch,{attrs:{"disabled":"","label":"Activer le mode mining"},model:{value:(_vm.isMiner),callback:function ($$v) {_vm.isMiner=$$v},expression:"isMiner"}}):_vm._e()],1)],1),_c('UserExternals',{attrs:{"user":_vm.user}}),_c(VRow,[_c(VCol,[_c('label',{attrs:{"for":"dateInvest"}},[_vm._v("Date de première investissement")]),_c('br'),_c(VDatePicker,{attrs:{"name":"dateInvest"},model:{value:(_vm.dateInvest),callback:function ($$v) {_vm.dateInvest=$$v},expression:"dateInvest"}})],1)],1),_c(VSpacer),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"success"},on:{"click":_vm.updateInvest}},[_vm._v(" Mettre à jour mon profil ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }