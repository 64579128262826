<template>
    <div class="mt-5">
            <v-row>
                <v-col cols="3" v-if="isPremiumUser()" >
                    <v-card outlined dense tile link>
                        <v-row  justify="center" align="center" @click="showBinanceKeys">
                            <v-col cols="3" class="pl-5">
                                <v-avatar size="40">
                                    <v-img src="/binance.png"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col class="">
                                <v-card-title>Binance</v-card-title>
                                <v-card-subtitle v-if="binanceKeysSaved" style="color:green">Activé</v-card-subtitle>
                                <v-card-subtitle  v-if="!binanceKeysSaved " style="color:red">Désactivé</v-card-subtitle>
                            </v-col>
                        </v-row>
                        <v-row v-if="isShowBinanceKeys && !binanceKeysSaved " class="pa-4">
                            <v-col>
                                <v-text-field v-model="binanceKey" label="Clé"/>
                            </v-col>
                        </v-row>
                        <v-row v-if="isShowBinanceKeys && !binanceKeysSaved" class="pa-4">
                            <v-col>
                                <v-text-field v-model="binanceSecret" label="Secret"/>
                            </v-col>

                        </v-row>
                        <v-row v-if="isShowBinanceKeys" class="pl-4 pb-2">
                            <v-col>
                                <v-btn  color="success"  class="mr-4"  @click="saveBinanceKeys" v-if="!binanceKeysSaved">
                                    Validate
                                </v-btn>
                                <v-btn color="warning"  class="mr-4"  @click="showBinanceKeys" v-if="!binanceKeysSaved">
                                    Annuler
                                </v-btn>
                                <v-btn color="error"  class="mr-4"  @click="deleteBinanceKeys" v-if="binanceKeysSaved">
                                    Supprimer
                                </v-btn>
                            </v-col> 
                        </v-row>
                        
                    </v-card> 
                </v-col>

                <v-col cols="3" v-if="isPremiumUser()" >
                    <v-card outlined dense tile link>
                        <v-row  justify="center" align="center" @click="showKucoinKeys">
                            <v-col cols="3" class="pl-5">
                                <v-avatar size="40">
                                    <v-img src="/kucoin.svg" :width="50"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col class="">
                                <v-card-title>Kucoin</v-card-title>
                                <v-card-subtitle v-if="kuCoinKeysSaved" style="color:green">Activé</v-card-subtitle>
                                <v-card-subtitle  v-if="!kuCoinKeysSaved " style="color:red">Désactivé</v-card-subtitle>
                            </v-col>
                        </v-row>
                        <div v-if="!kuCoinKeysSaved">
                            <v-row v-if="isShowKuCoinKeys" class="pa-4">
                                <v-col>
                                    <v-text-field v-model="kucoinKey" label="Clé"/>
                                </v-col>
                            </v-row>
                            <v-row v-if="isShowKuCoinKeys" class="pa-4">
                                <v-col>
                                    <v-text-field v-model="kucoinSecret" label="Secret"/>
                                </v-col>
                            </v-row>
                            <v-row v-if="isShowKuCoinKeys" class="pa-4">
                                <v-col>
                                    <v-text-field v-model="kucoinPhrase" label="Passphrase"/>
                                </v-col>
                            </v-row>
                        </div>

                        <v-row v-if="isShowKuCoinKeys" class="pl-4 pb-2">
                            <v-col>
                                <v-btn  color="success"  class="mr-4"  @click="saveKucoinKeys" v-if="!kuCoinKeysSaved">
                                    Validate
                                </v-btn>
                                <v-btn color="warning"  class="mr-4"  @click="isShowKuCoinKeys" v-if="!kuCoinKeysSaved">
                                    Annuler
                                </v-btn>
                                <v-btn color="error"  class="mr-4"  @click="deleteKucoinKeys" v-if="kuCoinKeysSaved">
                                    Supprimer
                                </v-btn>
                            </v-col>
                        </v-row>
                        
                    </v-card> 
                </v-col>
            
                <v-col cols="3" v-if="isPremiumUser()" >
                    <v-card outlined dense tile link>
                        <v-row  justify="center" align="center" @click="showFluxKeys">
                            <v-col cols="3" class="pl-5">
                                <v-avatar size="40">
                                    <v-img src="/runonflux.png"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col class="">
                                <v-card-title>Flux</v-card-title>
                                <v-card-subtitle v-if="fluxKeysSaved" style="color:green">Activé</v-card-subtitle>
                                <v-card-subtitle  v-if="!fluxKeysSaved " style="color:red">Désactivé</v-card-subtitle>
                            </v-col>
                        </v-row>
                        <v-row v-if="isSHowFluxKeys && !fluxKeysSaved" class="pa-4">
                            <v-col>
                                <v-text-field v-model="fluxAdresse" label="Adresse FLUX"/>
                            </v-col>
                        </v-row>
                        <v-row v-if="isSHowFluxKeys" class="pl-4 pb-2">
                            <v-col>
                                <v-btn v-if="!fluxKeysSaved" color="success"  class="mr-4"  @click="saveFluxAdresse">
                                    Validate
                                </v-btn>
                                <v-btn v-if="!fluxKeysSaved" color="warning"  class="mr-4"  @click="showFluxKeys">
                                    Annuler
                                </v-btn>
                                <v-btn color="error"  class="mr-4" @click="deleteFluxAdresse" v-if="fluxKeysSaved">
                                    Supprimer
                                </v-btn>
                            </v-col> 
                        </v-row>
                        
                    </v-card> 
                </v-col>

                <v-col cols="3" v-if="isPremiumUser()">
                    <v-card outlined dense tile link>
                        <v-row  justify="center" align="center" @click="showBotKeys">
                            <v-col cols="3" class="pl-5">
                                <v-avatar size="40">
                                    <path fill="currentColor" d="M23.0212 1.67671C21.3107 0.879656 19.5079 0.318797 17.6584 0C17.4062 0.461742 17.1749 0.934541 16.9708 1.4184C15.003 1.12145 12.9974 1.12145 11.0283 1.4184C10.819 0.934541 10.589 0.461744 10.3368 0.00546311C8.48074 0.324393 6.67795 0.885118 4.96746 1.68231C1.56727 6.77853 0.649666 11.7538 1.11108 16.652C3.10102 18.1418 5.3262 19.2743 7.69177 20C8.22338 19.2743 8.69519 18.4993 9.09812 17.691C8.32996 17.3997 7.58522 17.0424 6.87684 16.6135C7.06531 16.4762 7.24726 16.3387 7.42403 16.1847C11.5911 18.1749 16.408 18.1749 20.5763 16.1847C20.7531 16.3332 20.9351 16.4762 21.1171 16.6135C20.41 17.0369 19.6639 17.3997 18.897 17.691C19.3052 18.4993 19.7718 19.2689 20.3021 19.9945C22.6677 19.2689 24.8929 18.1364 26.8828 16.6466H26.8893C27.43 10.9731 25.9665 6.04728 23.0212 1.67671ZM9.68041 13.6383C8.39754 13.6383 7.34085 12.4453 7.34085 10.994C7.34085 9.54272 8.37155 8.34973 9.68041 8.34973C10.9893 8.34973 12.0395 9.54272 12.0187 10.994C12.0187 12.4453 10.9828 13.6383 9.68041 13.6383ZM18.3161 13.6383C17.0332 13.6383 15.9765 12.4453 15.9765 10.994C15.9765 9.54272 17.0124 8.34973 18.3161 8.34973C19.6184 8.34973 20.6751 9.54272 20.6543 10.994C20.6543 12.4453 19.6184 13.6383 18.3161 13.6383Z"></path>
                                </v-avatar>
                            </v-col>
                            <v-col class="">
                                <v-card-title>Supervision</v-card-title>
                                <v-card-subtitle v-if="botKeysSaved" style="color:green">Activé</v-card-subtitle>
                                <v-card-subtitle  v-if="!botKeysSaved " style="color:red">Désactivé</v-card-subtitle>
                            </v-col>
                        </v-row>
                        <v-row v-if="isShowBotKey && !botKeysSaved" class="pa-4">
                            <v-col>
                                <v-text-field v-model="botChannelId" label="ID Channel discord"/>
                            </v-col>
                        </v-row>
                        <v-row v-if="isShowBotKey" class="pl-4 pb-2">
                            <v-col>
                                <v-btn v-if="!botKeysSaved" color="success"  class="mr-4"  @click="saveBotChannel">
                                    Validate
                                </v-btn>
                                <v-btn v-if="!botKeysSaved" color="warning"  class="mr-4"  @click="showBotKeys">
                                    Annuler
                                </v-btn>
                                <v-btn color="error"  class="mr-4" @click="deleteBotChannel" v-if="botKeysSaved">
                                    Supprimer
                                </v-btn>
                            </v-col> 
                        </v-row>
                        
                    </v-card> 
                </v-col>
            </v-row>
            <div>
            <p style="color:red;" v-if="isPremiumUser()">
                Je ne prends aucune responsabilité sur les données qui sont stockées sur ce site. Pour éviter tout risque de perte financière lié au vol de clé, je te suggère très fortement de mettre des clés API autorisant uniquement la lecture de tes comptes.
                Je ne pourrais être tenu responsable de la perte de vos fond de quelque façon que ce soit. <br />
                Pour tout ce qui est fait sur ce site, seul le droit de lecture est nécessaire. Je ne te demanderai jamais tes mots de passes, un virement de fond de quelque façon que ce soit ou encore tes clés privées.
                Pour l'adresse FLUX, ce n'est qu'une adresse, donc pas de risque. Si tu n'as pas confiance, tu peux aussi te contenter de faire la saisie manuelle de tes fonds :)
            </p>
        </div>
        </div>
</template>

<script>
import ExchangeApiClient from '../../rest-externals/UserParamApiClient'

export default {

    props: {
        user: Object,
    },

    data: () => ({
        
        appKey: '',
        isShowBinanceKeys: false,
        isShowKuCoinKeys: false,
        isSHowFluxKeys: false,
        isShowBotKey: false,

        binanceKey: '',
        binanceSecret: '',
        binanceKeysSaved: false,

        kucoinKey: '',
        kucoinSecret: '',
        kucoinPhrase: '',
        kuCoinKeysSaved: false,

        fluxKey: '',
        fluxKeysSaved: false,
        fluxAdresse: "",

        botKey: '',
        botKeysSaved: false,
        botChannelId: "",
    
    }),

    mounted: function(){
        //Fonctionnalité desactive pour le moment.
        this.fetchExchange();
    },

    methods: {
        
        isPremiumUser : function(specificRole){

            let isPremium = false;
            let hasSpecificRole = false;

            if(this.user != null && this.user.roles != null){
                this.user.roles.forEach(element => {
                if( element === 'USER_PREMIUM' ){
                    isPremium = true
                }
                if( specificRole && element === specificRole ){
                    hasSpecificRole = true
                }
                });
                if( specificRole ){
                return isPremium && hasSpecificRole
                }
            }  
            return isPremium;
        },
        
        fetchExchange: async function(){
            this.clean();
            let response = await ExchangeApiClient.getAll();
            let data = response.data;

            this.binanceKeysSaved = data.hasBinance;
            this.kuCoinKeysSaved  = data.hasKucoin;
            this.fluxKeysSaved    = data.hasFlux;
            this.botKeysSaved     = data.hasBot;

        },

        clean: function(){
            this.binanceKey = '';
            this.binanceSecret = '';
            this.kucoinKey = '';
            this.kucoinSecret = '';
            this.kucoinPhrase = '';
            this.fluxKey = '';
            this.botKey = '';
        },

        showBinanceKeys: function(){
            this.isShowBinanceKeys = !this.isShowBinanceKeys;
        },

        showKucoinKeys: function(){
            this.isShowKuCoinKeys = !this.isShowKuCoinKeys;
        },

        showFluxKeys: function(){
            this.isSHowFluxKeys = !this.isSHowFluxKeys;
        },

        showBotKeys: function(){
            this.isShowBotKey = !this.isShowBotKey;
        },

        saveBinanceKeys: async function() {
            await ExchangeApiClient.createBinance( {
                "key": this.binanceKey,
                "secret": this.binanceSecret
            } )
            this.isShowBinanceKeys = false;
            this.fetchExchange();
        },

        saveKucoinKeys: async function() {
            await ExchangeApiClient.createKucoin( {
                "key": this.kucoinKey,
                "secret": this.kucoinSecret,
                "phrase": this.kucoinPhrase
            } )
            this.isShowKuCoinKeys = false;
            this.fetchExchange();
        },

        saveFluxAdresse: async function(){
            if( this.fluxAdresse == undefined | this.fluxAdresse == "" ){
                return;
            }
            await ExchangeApiClient.createFlux( {
                "address": this.fluxAdresse
            } )
            this.isSHowFluxKeys = false;
            this.fetchExchange()
        },

        saveBotChannel: async function(){
            if( this.botChannelId == undefined | this.botChannelId == "" ){
                return;
            }
            await ExchangeApiClient.createBot( {
                "key": this.botChannelId
            } )
            this.isShowBotKey = false;
            this.fetchExchange()
        },

        deleteBinanceKeys: async function(){
            await ExchangeApiClient.deleteBinance()
            this.binanceKeysSaved = false;
            await this.fetchExchange();
        },

        deleteKucoinKeys: async function(){
            await ExchangeApiClient.deleteKucoin()
            this.kuCoinKeysSaved = false;
            await this.fetchExchange();
        },

        deleteFluxAdresse: async function(){
            await ExchangeApiClient.deleteFlux()
            this.fluxKeysSaved = false;
            await this.fetchExchange();
        },

        deleteBotChannel: async function(){
            await ExchangeApiClient.deleteBot()
            this.botKeysSaved = false;
            await this.fetchExchange();
        },

    },
}
</script>
