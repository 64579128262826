<template>
    
    
        <v-expansion-panel @change="expended = !expended" class="pa-2">
            <v-expansion-panel-header>
                <template v-slot:default>
                    <v-row class="group-header">
                        <v-col>
                            <th>
                                <img :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/'+cmcId+'.png'" width="25px" />
                                {{groupName}}
                            </th>
                        </v-col>
                        <v-col align="center">
                            <th  class="pb-2">Prix d'achat moyen</th>
                            <div>{{prixAchatMoyen | toUSD}}</div>
                        </v-col>
                        <v-col align="center">
                            <th class="pb-2">Prix de vente moyen</th>
                            <div>{{prixVenteMoyen | toUSD}}</div>
                        </v-col>
                        <v-col align="center">
                            <th class="pb-2">Prix actuel</th>
                            <div> {{currentPrice | toUSD}}</div>
                        </v-col>
                        <v-col align="center">
                            <th class="pb-2">PNL</th>
                            <div :class="resultPnl>0?'gain-positif':'gain-negatif'">{{resultPnl | toUSD}}</div>
                        </v-col>
                    </v-row>
                
                <!--<v-row class="group-header" v-if="expended">
                    <v-col cols="12">
                        <img :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/'+cmcId+'.png'" width="25px" />
                        {{groupName}}
                    </v-col>
                </v-row>-->
                </template>
                
            </v-expansion-panel-header>

            <v-expansion-panel-content style="border:1px solid grey">
                <v-row style="background-color:grey;">
                    <v-col align="center">
                        Exchange
                    </v-col>
                    <v-col align="center">
                        Prix d'achat
                    </v-col>
                    <v-col align="center">
                        Prix de vente
                    </v-col>
                    <v-col align="center">
                        Quantité
                    </v-col>
                    <v-col align="center">
                        Total ordre
                    </v-col>
                    <v-col align="center">
                        PNL ordre
                    </v-col>
                    <v-col align="center">
                        Actions
                    </v-col>
                </v-row>

                <v-row v-for="item of items" :key="item.id">

                    <v-col align="center">
                        {{item.exchange }}
                    </v-col>

                    <v-col align="center">
                        {{item.prixAchat | toUSD}}
                    </v-col>

                    <v-col align="center">
                        <span v-if="item.prixVente">{{item.prixVente | toUSD}}</span>
                    </v-col>

                    <v-col align="center">
                        <span>{{item.nbCoin}}</span>
                    </v-col>

                    <v-col align="center">

                        <span v-if="item.prixAchat && !item.prixVente">
                            {{ item.nbCoin * item.prixAchat | toUSD}}
                        </span>

                        <span v-else
                            >
                            {{ (item.nbCoin * item.prixVente) | toUSD }}
                        </span>

                    </v-col>

                    <v-col align="center">

                        <span v-if="item.prixVente" :class="item.prixVente > item.prixAchat ? 'gain-positif':'gain-negatif'">
                            {{ (item.nbCoin * (item.prixVente - item.prixAchat) ) | toUSD }}
                        </span>

                    </v-col>

                    <v-col align="center">

                        <v-row justify="center">
                            <v-col cols="2">
                                <v-btn
                                    class="mr-3"
                                    v-if="!item.prixVente"
                                    fab
                                    dark
                                    small
                                    color="cyan" 
                                    @click="sold(item)"
                                    style="margin-right:3px;">
                                    <v-icon dark>
                                        mdi-currency-usd
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <v-col cols="2">
                                    <v-btn
                                        class="ml-3"
                                        fab
                                        dark
                                        small
                                        color="pink" 
                                        @click="deleteItem(item)">
                                        <v-icon dark>
                                            mdi-trash-can
                                        </v-icon>
                                    </v-btn>
                            </v-col>

                        </v-row>

                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>

</template>

<script>
import numeral from 'numeral'

export default {
    props:{ 
        items : Array,
        index: String
    },
    data: function() {
        return {
            groupName: "",
            cmcId: 0,
            expended: false,
            prixAchatMoyen: 0,
            prixVenteMoyen: 0,
            resultPnl: 0,
            currentPrice: 0
        }
    },

    filters: {
        toUSD: function( number ){
            return numeral(number).format('0,0[.]00 $')
        },
    },

    watch: {
        items: function(value){
            //this.items = newItems
            this.initValues(value)
            //console.log(this.items)
        }
    },

    mounted: function(){
        this.initValues()
    },

    methods: {

        initValues : function(){
            if( this.items && this.items.length > 0 ){
            this.computeGroupName()
            this.getCmcId()
            this.getCurrentPrice()
            this.computeMoyennePondereeAchat()
            this.computeMoyennePondereeVente()
            this.computePnl()
        }
        },

        getCurrentPrice: function(){
            this.currentPrice = this.items[0].marketData.quote.USD.price;
        },

        computeGroupName: function(  ){
            this.groupName = this.items[0].marketData.name
        },

        getCmcId: function(){
            this.cmcId = this.items[0].marketData.id
        },

        computeMoyennePondereeAchat: function(){
            let dividende = 0;
            let diviseur  = 0;
            for(const item of this.items){
                if( item.prixAchat > 0 && item.prixVente <= 0){
                    dividende = dividende + ( item.prixAchat * item.nbCoin );
                    diviseur += item.nbCoin;
                }
            }
            //console.log(items[0].marketData.name + ': ' + dividende +'/'+diviseur + ' = ' + dividende/diviseur);
            this.prixAchatMoyen = dividende/diviseur;
        },

        computeMoyennePondereeVente: function(){
            let dividende = 0;
            let diviseur  = 0;
            for(const item of this.items){
                if( item.prixVente > 0 ){
                    dividende = dividende + ( item.prixVente * item.nbCoin );
                    diviseur += item.nbCoin;
                }
            }
            if( diviseur == 0 ){
                return 0;
            }
            //console.log(items[0].marketData.name + ':' + dividende +'/'+diviseur);
            this.prixVenteMoyen = dividende/diviseur;
        },

        computePnl: function(){
            this.resultPnl = 0;
            let totalCoinSell = 0;
            let totalCoin = 0;
            for(const item of this.items){
                if( item.prixVente > 0 ){
                    this.resultPnl += ( (item.prixVente - item.prixAchat) * item.nbCoin );
                    totalCoinSell += item.nbCoin
                }
                totalCoin += item.nbCoin
            }
            if( totalCoinSell < totalCoin ){
                this.resultPnl += ( this.currentPrice * (totalCoin - totalCoinSell) ) - this.prixAchatMoyen * (totalCoin - totalCoinSell)
            }

        },

        sold: function(item){
            this.$emit( "sold", item )
        },

        deleteItem: function(item){
            this.$emit( "delete", item )
        },


    }

}
</script>

<style scoped>

.group-header{
    border: 20px;
}

.gain-positif{
    color: green;
}

.gain-negatif{
    color: red;
}
</style>