import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const UserApiClient = axios.create({
    baseURL: API_URL + '/api/v1/user',
    withCredentials: true
    /*headers: { 
        "X-AUTH" : "TODO"
    }*/
});

export default {

    updateInvest: async function( invest , dateInvest){
        return await UserApiClient.post('/invest', {'invest' : invest, 'dateInvest': dateInvest} );
    },

    getLoggedUser: async function(){
        return await UserApiClient.get('/loggedUser');
    }

}