import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const MetaApi = axios.create({
    baseURL: API_URL + '/api/v1/meta',
    withCredentials: true
    /*headers: { 
        "X-AUTH" : "TODO"
    }*/
});

export default {

    getVersion: async function(){
        return await MetaApi.get('/version');
    },

}