import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"300px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{attrs:{"elevation":"5","outlined":"","tile":""}},[(_vm.assets)?_c(VCardText,[_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v("Choix du token")]),_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.assets.data),function(asset){return _c(VListItem,{key:asset.id},[_c(VListItemIcon,{on:{"click":function($event){return _vm.createUsingId(asset.id)}}},[_c('img',{attrs:{"src":'https://s2.coinmarketcap.com/static/img/coins/128x128/'+asset.id+'.png'}})]),_c(VListItemContent,{on:{"click":function($event){return _vm.createUsingId(asset.id)}}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(asset.name)}})],1)],1)}),1)],1)],1):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" Annuler ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }