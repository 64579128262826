import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"300px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{attrs:{"elevation":"5","outlined":"","tile":""}},[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Nouveau coin")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,[_c(VAutocomplete,{attrs:{"label":"Symbole * (Ex. BTC)","items":_vm.availableCoins,"item-text":"symbol","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,{attrs:{"md":"4"}},[_c(VImg,{attrs:{"src":'https://s2.coinmarketcap.com/static/img/coins/64x64/'+item.id+'.png',"width":"30px"}})],1),_c(VCol,{attrs:{"md":"8"}},[_c('span',[_vm._v(_vm._s(item.name))])])],1)]}}]),model:{value:(_vm.selectedCoin),callback:function ($$v) {_vm.selectedCoin=$$v},expression:"selectedCoin"}})],1)],1)],1),_c('small',[_vm._v("*Champ obligatoire")])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.selectedCoin},on:{"click":_vm.createCoinWithId}},[_vm._v(" Ajouter ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelCreate}},[_vm._v(" Annuler ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }