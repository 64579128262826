<template>
  
  <v-row>
    <v-col class="pa-1">
      <TradingBotController />
    </v-col>
    <v-col class="pa-1">
      <FuturBotControllerVue />
    </v-col>
  </v-row>
  
</template>

<script>
import FuturBotControllerVue from './FuturBotController.vue'
import TradingBotController from './TradingBotController.vue'

export default {
    name: 'MainController',
    components: {
      FuturBotControllerVue,TradingBotController
    },
    data(){
        return {
            user: {}
        }
    },
}
</script>

<style>

</style>