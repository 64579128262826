<template>

  <div class="main">

      <v-row class="pa-1" cols="12" md="7" sm="12">
        <AlertController />
      </v-row>

      <DashboardController />    

      <BotController v-if="isPremiumUser('USER_TRADING_BOT')"/>
    
      <MasternodeController  v-if="isPremiumUser('USER_MASTERNODE')"/>

      <v-row>
        
        <v-col class="pa-1" md="12" sm="12"  order-sm="1">
              <MinerStatController @update-terminated="reload" @create-terminated="reload" />
        </v-col>

      </v-row>

      <v-row  >
        <v-col class="pa-1" cols="12" md="5" sm="12" order-sm="1">
            <WalletRepartitionChartController />
        </v-col>
        <v-col class="pa-1" cols="12" md="7" sm="12" order-sm="2">
            <WalletSnapshotChartController :user="user"/>
        </v-col>
      </v-row>

  </div>
</template>

<script>
import MinerStatController from './MinerStatController.vue'
import DashboardController from './DashboardController.vue'
import BotController from './bot/BotController.vue';
import WalletSnapshotChartController from './WalletSnapshotChartController.vue';
import WalletRepartitionChartController from './WalletRepartitionChartController.vue';
import UserRestApi from '../rest-externals/UserActionsApiClient';
import SSEClient from '../rest-externals/SSEClient';
import AlertController from './AlertComponent.vue';
import MasternodeController from './masternodeController/MasternodeController.vue'

export default {
  name: 'MainController',
  components: {
    MinerStatController,DashboardController, BotController, WalletSnapshotChartController, WalletRepartitionChartController, AlertController, MasternodeController
  },
  data(){
      return {
          user: {}
      }
  },
  methods: {
    reload: function(){
    },
    isPremiumUser : function(specificRole){

      let isPremium = false;
      let hasSpecificRole = false;

      if(this.user != null && this.user.roles != null){
        this.user.roles.forEach(element => {
          if( element === 'USER_PREMIUM' ){
            isPremium = true
          }
          if( specificRole && element === specificRole ){
            hasSpecificRole = true
          }
        });
        if( specificRole ){
          return isPremium && hasSpecificRole
        }
      }  
      return isPremium;
    }
  },
  mounted: async function(){
    try{
      var response = await UserRestApi.getLoggedUser();
      this.user = response.data;
      SSEClient.openSse();
    }catch( error ){
      //if( error.response.status === 401 ){
        this.$router.push('/login')
        this.$router.go('login')
      //}
    }
  }
}
</script>

<style>
.clickable:hover{
  cursor: pointer;
}

.main{
  max-width: 90%;
  margin: auto;
}

</style>
