import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const tradeApiClient = axios.create({
    baseURL: API_URL + '/api/v1/trades',
    withCredentials: true
});

export default {

    getTradingAccount: async function(){
        return await tradeApiClient.get('/spot/wallet');
    },

    getTradingHistory: async function(){
        return await tradeApiClient.get( '/spot/history' );
    },

    getFuturAccount: async function(){
        return await tradeApiClient.get( '/futur/wallet' );
    },

    getFuturHistory: async function(){
        return await tradeApiClient.get( '/futur/history' );
    },

}