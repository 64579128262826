<template>
    
    <v-card outlined tile>
        <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
    </v-card>

</template>
 
<script>

import SnapshotApiClient from '../rest-externals/SnapshotApiClient'
import numeral from 'numeral'
import moment from 'moment'

export default {
  props: {
    user: Object,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          backgroundColor: "#1E1E1E",
          plotBackgroundColor: "#1E1E1E",
          plotBorderWidth: null,
          plotShadow: false,
          type: "line"
        },
        title: {
          text : "Evolution du wallet",
          style: {
            color: "#ffffff"
          }
        },
        tooltip: {
          formatter: function () {
              return "Le " + moment.unix(this.x/1000).format("DD/MM/YYYY")  +
                  '<br /> Wallet : <b>' + this.y + ' $</b>';
          }
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: [
            {
            title: {
              text: 'Total en $',
                style:{
                  color: 'white'
                }
              }
            },
            {
              title: {
                text: 'Bot Wallet $',
                  style:{
                    color: 'orange'
                }
              },
              opposite: true
            }
        ],
        series: []
      }
    };
  },
  filters: {
        toUSD: function( number ){
            return numeral(number).format('00[.]00')
        }
  },
  mounted(){
      this.fetchData()
  },
  methods: {
    isPremiumUser : function(specificRole){

      let isPremium = false;
      let hasSpecificRole = false;

      if(this.user != null && this.user.roles != null){
        this.user.roles.forEach(element => {
          if( element === 'USER_PREMIUM' ){
            isPremium = true
          }
          if( specificRole && element === specificRole ){
            hasSpecificRole = true
          }
        });
        if( specificRole ){
          return isPremium && hasSpecificRole
        }
      }  
      return isPremium;
    },

    fetchData: async function(){
        var response = await SnapshotApiClient.getAllWalletSnapshot();
        if( response ){
            var snapshot = response.data;

            var totalHistoricalDataMap = [];

            for( const entry of snapshot ){

              if( !totalHistoricalDataMap[entry.wallet] ){
                totalHistoricalDataMap[entry.wallet] = []
              }

              totalHistoricalDataMap[entry.wallet].push( {
                y : Number(numeral(entry.total).format('00[.]00')),
                x : new Date(entry.date).getTime()
              })

            }

            this.chartOptions.series.push( 
              {
                name: 'Evolution du portefeuille crypto',
                data : totalHistoricalDataMap["spot"],
                yAxis: 0,
                color: 'rgb(124, 181, 236)'
              } )
              
            if( this.isPremiumUser() ){
              this.chartOptions.series.push( {
                name: 'Evolution bot 1',
                data : totalHistoricalDataMap["bot-spot"],
                yAxis: 1,
                color: 'rgba(246,169,97,255)'
              })

              this.chartOptions.series.push({
                name: 'Evolution bot futur',
                data : totalHistoricalDataMap["bot-futur"],
                yAxis: 1,
                color: 'rgba(210,66,24,255)'
              })
            }
        }
    }
  }
};
</script>
