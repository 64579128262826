<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
            <v-col>
                <v-text-field v-model="name" :counter="10" label="Prénom" readonly />
            </v-col>
            <v-col>
                <v-text-field v-model="lastName" :counter="10" label="Nom" readonly />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-text-field v-model="email" label="E-mail" readonly/>
            </v-col>
            <v-col  >
                <div v-if="isPremiumUser('USER_MINER')" >
                    <v-text-field v-model="appKey" label="Clé" readonly/>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center" align="center">
        
            <v-col>
                <v-text-field v-model="invest" label="Investissement (en $)"/>
            </v-col>
            
            <v-col>
                <v-switch  v-model="isMiner" disabled label="Activer le mode mining" v-if="isPremiumUser('USER_MINER')" />
            </v-col>
        </v-row>

        <UserExternals :user="user"/>

        <v-row>
           <v-col>
                <label for="dateInvest">Date de première investissement</label><br />
                <v-date-picker name="dateInvest" v-model="dateInvest"></v-date-picker>
            </v-col>
        </v-row>

        <v-spacer />

        <v-row>
            <v-col>
                <v-btn color="success" @click="updateInvest">
                    Mettre à jour mon profil
                </v-btn>
            </v-col>
        </v-row>

    </v-form>
  </v-container>
</template>

<script>
import UserActionApiClient from '../../rest-externals/UserActionsApiClient'
import ExchangeApiClient from '../../rest-externals/UserParamApiClient'
import moment from 'moment';
import UserExternals from './UserExternals.vue'

export default {

    data: () => ({
        user: Object,
        valid: true,
        name: '',
        lastName: '',
        nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        email: '',
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        appKey: '',
        isShowBinanceKeys: false,
        isShowKuCoinKeys: false,
        
        binanceKey: '',
        binanceSecret: '',
        binanceKeysSaved: false,

        KucoinKey: '',
        KucoinSecret: '',
        kuCoinKeysSaved: false,

        fluxKey: '',
        fluxKeysSaved: false,

        botKey: '',
        botKeysSaved: false,

        isMiner: false,
        invest: 0,
        dateInvest: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    }),

    components: {
        UserExternals
    },

    mounted: function(){
        this.fetchUser();
        //Fonctionnalité desactive pour le moment.
        this.fetchExchange();
    },

    methods: {

        isPremiumUser : function(specificRole){

            let isPremium = false;
            let hasSpecificRole = false;

            if(this.user != null && this.user.roles != null){
                this.user.roles.forEach(element => {
                if( element === 'USER_PREMIUM' ){
                    isPremium = true
                }
                if( specificRole && element === specificRole ){
                    hasSpecificRole = true
                }
                });
                if( specificRole ){
                return isPremium && hasSpecificRole
                }
            }  
            return isPremium;
        },

        fetchExchange: async function(){
            this.clean();
            let response = await ExchangeApiClient.getAll();
            let data = response.data;

            this.binanceKeysSaved = data.hasBinance;
            this.kuCoinKeysSaved  = data.hasKucoin;
            this.fluxKeysSaved    = data.hasFlux;
            this.botKeysSaved     = data.hasBot;

        },
        fetchUser: async function(){
            let response = await UserActionApiClient.getLoggedUser();
            this.user = response.data;

            this.name = this.user.firstName;
            this.lastName = this.user.lastName;
            this.email = this.user.email;
            this.appKey = this.user.userKey;
            this.isMiner = this.user.isMiner;
            this.invest = this.user.invest;
            if( this.user.dateInvest ){
                this.dateInvest =  new Date(this.user.dateInvest).toISOString().substr(0, 10);
            }
        },
       
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        clean: function(){
            this.binanceKey = '';
            this.binanceSecret = '';
        },
        showBinanceKeys: function(){
            if( this.isShowBinanceKeys ){
                this.isShowBinanceKeys = false;
            }else{
                this.isShowBinanceKeys = true
            }
            
        },
        saveBinanceKeys: async function() {
            await ExchangeApiClient.createBinanceExchange( {
                "apikey": this.binanceKey,
                "secret": this.binanceSecret
            } )
            this.binanceKeysSaved = true;
            this.isShowBinanceKeys = false;
            this.fetchExchange();
        },
        deleteBinanceKeys: async function(){
            await ExchangeApiClient.delete( {
                "name": "binance"
            })
            this.isShowBinanceKeys = false;
            this.binanceKeysSaved = false;
            await this.fetchExchange();
        },
        updateInvest: async function(){
            console.log(moment(this.dateInvest))
            await UserActionApiClient.updateInvest( this.invest, new Date(this.dateInvest) );
        }
    },
}
</script>
