<template>

    <v-row justify="center" v-if="actif && actif.marketData">
        <v-dialog v-model="active" persistent max-width="1000px">

            <v-card elevation="5" outlined tile>
                <v-card-title>
                    <span class="text-h5">Mettre à jour le portefeuille de {{actif.marketData.name}} ({{actif.marketData.symbol}})</span>
                </v-card-title>

                <v-card-text>
                    
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-center">Exchange</th>
                                <th class="text-center">Wallet</th>
                                <th class="text-center">Nombre de coin</th>
                                <th class="text-center">Montant</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in coins" :key="item.id" class="text-center">
                                <td>
                                    <v-text-field v-model="item.exchange" :disabled="item.provisionning"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="item.wallet" :disabled="item.provisionning"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field v-model="item.nbCoin" :disabled="item.provisionning"></v-text-field>
                                </td>
                                <td>
                                    {{ (item.nbCoin * actif.marketData.quote.USD.price) | toUSD }}
                                </td>
                            </tr>
                        </tbody>

                    </v-simple-table>

                </v-card-text>
                <v-card-actions class="justify-right">
                    <v-btn-toggle>
                        <v-btn color="blue-grey" @click="onCancel">
                            <v-icon left dark >
                                mdi-close-octagon-outline
                            </v-icon>
                            Annuler
                        </v-btn>
                        <v-btn color="blue-grey" @click="onConfirm">
                            <v-icon left dark >
                                mdi-content-save-move
                            </v-icon>
                            Enregistrer
                        </v-btn>
                        <v-btn color="blue-grey" @click="onDelete">
                            <v-icon left dark >
                                mdi-trash-can
                            </v-icon>
                            Supprimer
                        </v-btn>
                        <v-btn color="blue-grey" @click="onAddEntry">
                            <v-icon left dark >
                                mdi-wallet-plus
                            </v-icon>
                            Ajouter un wallet
                        </v-btn>
                    </v-btn-toggle>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-row>

    <!--<div v-if="active">
        <md-dialog :md-active.sync="active">
            <md-dialog-title>Mettre à jour le portefeuille de {{actif.marketData.name}} ({{actif.marketData.symbol}})</md-dialog-title>

            <md-table v-model="coins" class="actif-update-coin-form">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <td>
                        {{ item.symbol_fk }}
                    </td>
                    <td>
                            <md-input v-model="item.exchange"></md-input>
                            <span class="md-helper-text">Nom de l'exchange</span>
                    </td>
                    <td>
                                <md-input v-model="item.wallet"></md-input>
                                <span class="md-helper-text">Wallet</span>
                    </td>
                    <td>
                                <md-input v-model="item.nbCoin"></md-input>
                                <span class="md-helper-text">Nombre de coin</span>
                    </td>
                </md-table-row>
            </md-table>

<v-icon right dark >
                            mdi-cloud-upload
                        </v-icon>
            <md-dialog-actions>
                <v-icon right dark >
                            mdi-cloud-upload
                        </v-icon>
                <v-btn class="md-primary" @click="onCancel">Annuler</v-btn>
                <v-icon right dark >
                            mdi-cloud-upload
                        </v-icon>
                <v-btn class="md-primary" @click="onConfirm">Enregistrer</v-btn>
                <v-icon right dark >
                            mdi-cloud-upload
                        </v-icon>
                <v-btn class="md-primary" @click="onDelete">Supprimer</v-btn>
                <v-btn class="md-primary" @click="onAddEntry">Ajouter un wallet</v-btn>
            </md-dialog-actions>
        </md-dialog>
    </div>-->
</template>

<script>

import ActifsApi from '../rest-externals/ActifsApiClient'
import EventBus from './EventBus.js'
import WalletApi from '../rest-externals/WalletApiClient'
import numeral from 'numeral'

export default {

    data(){
        return {
            coins: []
        }
    },

    props: {
        actif: Object,
        active: Boolean
    },
    filters: {
        toUSD: function( number ){
            return numeral(number).format('0,0[.]00 $')
        },
    },
    created(){
        this.mutableActive = this.active;
    },
    watch:{
        actif: function(value){
            this.coins = value.coins ? value.coins.map( function(c) { var clone = {};Object.assign(clone, c); return clone; }) : [];
            if( this.coins.length === 0 ){
                this.onAddEntry()
            }
        }
    },

    methods: {

        onConfirm: async function(){
            //await ActifsApi.updateActif(  this.actif.symbol , this.nbCoin )
            //TODO : ajouter l'actif pour connaitre l'id de l'actif a mettre a jour.
            await WalletApi.createOrUpdateList( this.coins )
            EventBus.$emit("update-terminated", {reload: true})
        },
        onCancel: function(){
            EventBus.$emit("update-terminated");
            this.coins = this.actif.coins ? this.actif.coins.map( function(c) { var clone = {};Object.assign(clone, c); return clone; }) : [];
        },
        onDelete: async function(){
            await ActifsApi.deleteActif(  this.actif.symbol )
            EventBus.$emit("update-terminated", {reload: true})
        },
        onAddEntry: function(){
            this.coins.push( {
                "symbol_fk": this.actif.marketData.symbol,
                "exchange" : 'Exchange a definir',
                "wallet"   : "spot",
                "nbCoin"   : 0
            });
        }

    }
}
</script>

<style lang="css" scoped>

.actif-update-coin-form{
    margin: 10px;
}

</style>