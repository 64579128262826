import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const FngApiClient = axios.create({
    baseURL: API_URL + '/api/v1/fng',
    withCredentials: true
});

export default {

    getFearAndGreed: async function(){
        return await FngApiClient.get('/');
    },

}