import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const MinerStatsApi = axios.create({
    baseURL: API_URL + '/api/v1/actifs',
    withCredentials: true
    /*headers: { 
        "X-AUTH" : "TODO"
    }*/
});

export default {

    getAllActifs: async function(){
        return await MinerStatsApi.get('/');
    },

    createActif: async function( symbol, nbCoin ){
        return await MinerStatsApi.post( '/create', {
            'symbol': symbol,
            'nbCoin': nbCoin
        });
    },

    createActifUsingId: async function( id ){
        return await MinerStatsApi.post( '/create-with-cmc-id', {
            'cmcId': id
        });
    },

    deleteActif: async function( symbol ){
        return await MinerStatsApi.delete( '/delete', { data: { 'symbol': symbol } } )
    },

    updateActif: async function( symbol, nbCoin ){
        return await MinerStatsApi.post( '/update', 
        {
            'symbol': symbol,
            'nbCoin': nbCoin
        });
    },

    getTotalAmount: async function(){
        return await MinerStatsApi.get('/total')
    },

    getTopGain: async function() {
        return await MinerStatsApi.get('/top-gain')
    },

    getTopLoss: async function() {
        return await MinerStatsApi.get('/top-loss')
    },

    getRentabilite: async function() {
        return await MinerStatsApi.get('/rentabilite')
    },

    getEvolution: async function() {
        return await MinerStatsApi.get('/evolution')
    },

    refreshCache: async function() {
        return await MinerStatsApi.get('/refresh-cache')
    },

    getCoinData: async function( coinMarketCapId ){
        return await MinerStatsApi.get('/coin', {
            params : {
                id: coinMarketCapId
            }
        })
    }

}