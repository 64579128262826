<template>
    <v-container>
        <h2>A propos</h2>
        <v-card>
                
            <v-card-text>
                <p class="about">
                    Bonjour à toi jeune cryptoman! <br /><br />
                    Je suis Mathieu, développeur et passionné par les nouvelles technologies qui prennent une grosse partie de mon temps libre.<br />
                    A mes heures perdu je mine des cryptos et je joue un peu avec sur mes exchanges préférés.<br />
                    Comme les cryptos ne sont pas toutes disponibles au même endroit, j'en ai un peu partout! Alors pour me faciliter la vie j'ai décidé de développer cette application. Oui, tu as raison il en existe d'autres dans le même genre! Mais toutes celles que j'ai trouvés sont payantes pour l'instant.<br />
                    Celle-ci n'est pas parfaite, mais elle est gratuite.
                    Mon objectif est de donner une vue globale et centralisée de l'ensemble des cryptos que je possède.
                    <br />
                    Pas de panique, je n'ai pas dit que les cryptos sont sur cette plateforme! Toutes les informations qui sont sur ce sites sont saisies manuellement et ne sont que le reflet de tes wallets de crypto.<br />
                    Aucune crainte de se faire voler quoi que ce soit donc, il te suffit simplement de saisir où se trouvent tes cryptos et de mettre à jour l'application de temps en temps pour savoir où tu en es dans tes investissements.<br />
                    Et concernant la sécurité de ton mot de passe, il n'y a pas non plus de crainte à avoir car c'est google qui gère l'authentification. Le mot de passe ne passe donc pas sur le site directement!<br />
                    Alors si tu es convaincu, il ne te reste plus qu'à suivre les quelques explications données dans la section "Guide utilisateur" juste au dessous.
                </p>
            </v-card-text>

        </v-card>
            <br />
                <v-tabs v-model="tab">
                    <v-tab>Guide utilisateur</v-tab>
                    <v-tab>Bot de supervision</v-tab>
                    <v-tab>Fonctionnalités</v-tab>
                    <v-tab>Contact</v-tab>
                    <v-tab>Anomalies</v-tab>
                </v-tabs>   
                <v-tabs-items v-model="tab">
                    <v-tab-item >
                        <v-card flat>
                            <v-card-text>

                                <v-carousel v-model="carroussel" cycle>
                                    <v-carousel-item>
                                        <v-sheet>
                                                <v-row class="fill-height ma-3" align="center" justify="center">
                                                    <v-col>
                                                        Cliquer sur le <img src="@/assets/guide/add-coin-plus.png" alt="add-coin" width="5%" class="pa-2" /> 
                                                        du tableau Coins (cf. image ci-dessous), pour ajouter des nouvelles cryptos. Une popup s'ouvre et demande de saisir un symbole. Ici il faut saisir symbole disponible sur CoinMarketCap, par exemple "BTC" puis valider en cliquant sur le bouton "Ajouter".
                                                    </v-col>
                                                </v-row>
                                                <v-row align="center" justify="center">
                                                    <v-col class="text-center">
                                                        <img src="@/assets/guide/add-coin-1.png" alt="add-coin" width="80%" class="pa-2" />
                                                    </v-col>
                                                </v-row>
                                        </v-sheet>
                                    </v-carousel-item>
                                    <v-carousel-item>
                                        <v-sheet>
                                                <v-row
                                                class="fill-height ma-3"
                                                align="center"
                                                justify="center"
                                                >
                                                    <v-col class="text-center">
                                                        Exemple avec Cardano
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col class="text-center">
                                                        <img src="@/assets/guide/add-coin-2.png" alt="add-coin" width="80%" class="pa-2" />
                                                    </v-col>
                                                </v-row>
                                        </v-sheet>
                                    </v-carousel-item>
                                    <v-carousel-item>
                                        <v-sheet>
                                                <v-row
                                                class="fill-height ma-3"
                                                align="center"
                                                justify="center"
                                                >
                                                    <v-col>
                                                        Ajouter un nouveau wallet pour la nouvelle crypto, pour cela clickez sur la colonne "nombre de coin"<br />
                                                        
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col class="text-center">
                                                        <img src="@/assets/guide/add-coin-3.png" alt="add-coin" width="60%" class="pa-2" />
                                                    </v-col>
                                                </v-row>
                                        </v-sheet>
                                    </v-carousel-item>
                                    <v-carousel-item>
                                        <v-sheet>
                                                <v-row
                                                class="fill-height ma-3"
                                                align="center"
                                                justify="center"
                                                >
                                                    <v-col>
                                                        Une nouvelle popup va s'ouvrir pour saisir les différents exchange sur lesquels vous possédez vos cryptomonnaies.<br />
                                                        Pour compléter cette popup, il faut mettre le nom de l'exchange en première colonne, le wallet dans la seconde et enfin le nombre de coin possédé dans la troisième.<br />
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col class="text-center">
                                                        <img src="@/assets/guide/add-coin-4.png" alt="add-coin" width="60%" class="pa-2" />
                                                    </v-col>
                                                </v-row>
                                        </v-sheet>
                                    </v-carousel-item>
                                    <v-carousel-item>
                                        <v-sheet>
                                                <v-row
                                                class="fill-height ma-3"
                                                align="center"
                                                justify="center"
                                                >
                                                <div>
                                                    Le prix du coin est automatiquement rafraîchit à chaque recharge de page depuis CoinMarketCap (avec une latence pouvant aller jusqu'à 30 min). Cette valeur pourra évoluer dans le temps afin d'être réduite.
                                                </div>
                                                </v-row>
                                        </v-sheet>
                                    </v-carousel-item>
                                </v-carousel>

                               <!--<p>
                                    Sur le petit + du tableau Coins (1), tu peux ajouter des nouvelles cryptos. Une popup s'ouvre et demande de saisir un symbole. Ici il faut saisir symbole disponible sur CoinMarketCap, par exemple "BTC" puis valider en cliquant sur le bouton "Ajouter".<br />
                                    <br />
                                    <img src="@/assets/guide/add-coin-1.png" alt="add-coin" width="50%" class="pa-2" />
                                    <img src="@/assets/guide/add-coin-2.png" alt="add-coin" width="50%" class="pa-2" /><br /><br />

                                    Ajouter un nouveau wallet pour la nouvelle crypto, pour cela clickez sur la colonne "nombre de coin"<br />
                                    <img src="@/assets/guide/add-coin-3.png" alt="add-coin" width="800px" class="pa-2" /><br /><br />

                                    Une nouvelle popup va s'ouvrir pour saisir les différents exchange sur lesquels vous possédez vos cryptomonnaies.<br />
                                    Pour compléter cette popup, il faut mettre le nom de l'exchange en première colonne, le wallet dans la seconde et enfin le nombre de coin possédé dans la troisième.<br />
                                    <img src="@/assets/guide/add-coin-4.png" alt="add-coin" width="800px" class="pa-2" /><br />

                                    Le prix du coin est automatiquement rafraîchit à chaque recharge de page depuis CoinMarketCap (avec une latence pouvant aller jusqu'à 30 min). Cette valeur pourra évoluer dans le temps afin d'être réduite.
                                    
                                </p>-->

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                     <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <h2 class="text-center ma-4">
                                    <a href="https://discord.com/api/oauth2/authorize?client_id=838022899696402502&permissions=292057787392&scope=bot"> J'invite le Bot de supervision sur mon rig préféré! </a><br />
                                </h2>
                                <div>
                                    <p>
                                        Pré-requis : <br />
                                        <ul>
                                            <li>Utiliser EMOS ou HiveOS</li>
                                            <li>Savoir ouvrir un terminal/console sur le rig.</li>
                                        </ul>
                                    </p>
                                </div>
                                <v-spacer />
                                <p class="text-justify ma-4" >

                                    Comment créer un bot de supervision de ton rig préféré : 
                                    <ul class="modop">
                                        <li>Ajoute le bot à l'un de tes channels discord. Pour cela, créé un serveur si tu n'en a pas. 
                                            (Besoin d'aide ? <a href="https://www.frandroid.com/comment-faire/tutoriaux/951351_comment-creer-et-parametrer-un-serveur-discord%E2%80%89">Créer un serveur discord</a>
                                        </li>
                                        <li>
                                            Copie l'identifiant du channel sur lequel tu veux être alerté
                                        </li>
                                        <li>
                                            Colle l'identifiant sur la page de ton <router-link :to="{name: 'user'}">profil utilisateur</router-link>
                                        </li>
                                        <li>
                                            Pour pouvoir être informé de l'état du rig, il faut installer un petit outil. 
                                            Sur une console linux, taper la ligne suivante : <br />
                                            <code>
                                                curl https://www.my-crypto-hub.com/worker/install.sh | sh <br />
                                            </code>

                                        </li>
                                        <ul>
                                            <li>
                                                Pour HiveOS : <br />
                                                <code>
                                                    echo "USER_KEY={{userKey}}" > /root/miner-gate/config 
                                                </code>
                                            </li>
                                            <li>
                                                Pour EMOS : <br />
                                                <code>
                                                    echo "USER_KEY={{userKey}}" > /home/miner/miner-gate/config <br/>
                                                </code>
                                                <br />
                                            </li>
                                        </ul>
                                        <li>
                                            A partir de ce point, le rig va remonter toute les minutes les stastiques suivantes : 
                                            <ul>
                                                <li>Température du GPU</li>
                                                <li>Vitesse des ventilateurs</li>
                                                <li>Hashrate par carte</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Une fois les premières remontées de statistiques effectuées, il est alors possible de paramétrer les seuils d'alarming.<br />
                                            Pour cela, rendez-vous sur la page <router-link :to="{name: 'alarms'}">alarmes</router-link>
                                        </li>
                                    </ul>
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p>
                                    <ul>
                                        <li>Rafraichissement automatique des prix depuis CoinMarketCap</li>
                                        <li>Aggregation de l'ensemble des actifs</li>
                                        <li>Actif du portefeuille ayant le mieux performé</li>
                                        <li>Actif du portefeulle ayant le plus baissé de valeur</li>
                                        <li>Sauvegarde journalière à 3h du matin du montant total du portefeuille</li>
                                        <li>Représentation graphique de l'évolution du portefeuille au cours du temps</li>
                                        <li>Répartition graphique du contenu du portefeuille</li>
                                    </ul>
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="text-center">
                                    Rejoignez-nous sur <a href="https://discord.gg/NmShyshy">Discord</a>
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p>
                                    Anomalie en cours :
                                    <ul>
                                        <li>Suppression d'un wallet</li>
                                    </ul>
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
           
            

    </v-container>

</template>

<script>
import UserRestApi from '../rest-externals/UserActionsApiClient';
export default {
    data () {
        return {
        tab: null,
        carroussel: {},
        userKey: ""
        }
    },
    mounted: async function(){
        try{
            var response = await UserRestApi.getLoggedUser();
            this.userKey = response.data.userKey;
        }catch( error ){
            console.log(error)
        }
    }
}
</script>

<style lang="css" scoped>

.about{
    text-align: justify;
    text-justify: inter-word;
}

.modop li{
    padding-top: 5px;
}

code{
    padding: 5px;
}

</style>