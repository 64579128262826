<template>
    
    <v-card outlined tile>
        <highcharts class="hc" :options="chartOptions" ref="pie"></highcharts>
    </v-card>

</template>
 
<script>

import numeral from 'numeral'
import ActifsApiClient from '../rest-externals/ActifsApiClient'

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          backgroundColor: "#1E1E1E",
          plotBackgroundColor: "#1E1E1E",
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie"
        },
        title: {
          text: "Répartition du portefeuille",
          style: {
            color: "#ffffff"
          }
        },
        labels: {
          style: {
            backgroundColor: "#1E1E1E"
          }
        },
        legend: {
          backgroundColor: "#1E1E1E",
        },
        tooltip: {
           formatter: function () {
             return this.point.name + ": " + Number(numeral(this.point.percentage).format('00[.]00')) + " %"
           }
          
        },
        //exemple : https://codesandbox.io/s/y0x2561vk9?file=/src/components/Chart.vue
        series: []
      }
    };
  },
  filters: {
        toUSD: function( number ){
            return numeral(number).format('00[.]00')
        }
  },
  mounted(){
      this.chartOptions.series = [];
      this.fetchData()
  },
  methods: {
      fetchData: async function(){
          var response = await ActifsApiClient.getAllActifs();
          var actifs = response.data.actifs

          var dataSerie = [];
          var labels = [];
          for(const actif of actifs){
            dataSerie.push({
              "name": actif.marketData.name,
              "y": Number(numeral(actif.total).format("00[.]00")),
              "allData": actif,
              "dataLabels": {
                "formatter": function(){
                  return this.point.name
                },
                className:"light-label",
              }
            })
            labels.push({
              html: "<div>"+actif.marketData.name+" : "+ numeral(actif.total).format("00[.]00") +"</div>"
            })
          }
          this.chartOptions.series.push( {
            "name": "Wallet",
            "colorByPoint": true,
            "data": dataSerie,
            
          });
          this.chartOptions.labels.items = labels;
      }
  }
};
</script>

<style lang="css">
.light-label.highcharts-data-label text, .light-label.highcharts-data-label text tspan{
	color: white !important;
  fill: white !important;
	text-shadow: none !important;
  font-size: 13px !important;
  stroke: none !important;
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 400 !important;
}
</style>
