<template>

  <v-card outlined tile height="200" min-width="210" :loading="loading < 2">
    <v-card-title>
       <v-icon role="img" class="offline">mdi-cloud-off</v-icon>Masternode flux
    </v-card-title>
    <v-card-text>
        <div>
            Benchmark : <br />
            ip : <br />
            Noeud : CUMULUS<br />
            Status : <br />
            Espace libre :<br />
            Host : Mathieu-GN41 <br />
        </div>
    </v-card-text>
  </v-card>
  
</template>

<script>

import numeral from 'numeral'


export default {

    data: function(){
        return {
            loading: 0,
            wallet: {},
            trades: []
        }
    },

    filters: {
        toDate: function( timestamp ){
            return new Date( timestamp/1000000 ).toLocaleString()
        },
        toUSD: function( number ){
            return numeral(number).format('0[.]00')
        }
    },

    mounted: async function(){
     
    },

    methods: {
        
    }
}
</script>

<style lang="css"  scoped>

.online{
    color: green;
    padding-right: 5px;
}

.offline{
  color: red;
  padding-right: 8px;
}

</style>