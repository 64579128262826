<template>
    <v-row justify="center">

        <v-dialog v-model="showPopup" persistent max-width="1000px">

            <v-card outlined shape tile :loading="fetchPriceInProgress">

                <template slot="progress">
                    <v-progress-linear
                        color="primary"
                        height="5"
                        indeterminate
                        absolute
                    ></v-progress-linear>
                </template>

                <v-container>
                    <AlertComponent />
                </v-container>

                <v-card-title>
                    <span v-if="mode != 'sold'">
                        Ajouter un trade
                    </span>
                    <span v-else>
                        Enregistrer une vente
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col  >
                            <v-autocomplete
                                :disabled="mode == 'sold'"
                                :items="availableCoins"
                                item-text="name"
                                item-value="id"
                                v-model="formAsset"
                                @change="fetchCoinPrice"
                            >
                                <template v-slot:item="{item}">
                                    <v-row>
                                        <v-col md="4">
                                            <v-img :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/'+item.id+'.png'"  width="30px" />
                                        </v-col>
                                        <v-col md="8">
                                            <span>{{item.name}}</span>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col  >
                            <v-text-field v-model="formQuantity" label="Quantite" required />
                        </v-col>

                        <v-col  >
                            <v-text-field v-model="formExchange" label="Exchange" required />
                        </v-col>

                        <v-col  >
                            <v-text-field v-model="formAchat" label="Prix d'achat" required :disabled="mode == 'sold'"/>
                        </v-col>

                        <v-col v-if="mode =='sold'" >
                            <v-text-field
                                v-if="mode =='sold'"
                                v-model="formVente"
                                label="Prix de vente"
                                required
                            ></v-text-field>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-center">
                    <v-btn @click="add" color="success" v-if="!formVente && formValid && mode !='sold'">
                        Achat
                    </v-btn>
                    <v-btn @click="sell" color="error" v-if="(formVente && formValid) || mode =='sold'">
                        Vente
                    </v-btn>
                    <v-btn @click="cancel" color="primary">
                        Annuler
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import EventBus from '../EventBus.js'
import OrderApiClient from '../../rest-externals/order/OrderApiClient'
import ActifsApiClient from '../../rest-externals/ActifsApiClient'
import AlertComponent from '../AlertComponent.vue'
import coinmarketcapApiClient from '../../rest-externals/CoinMarketcapApiClient';

export default {
    props: {
        showPopup: Boolean,
        mode: String,
        data: Object
    },
    components: {
        AlertComponent
    },
    data(){
        return {
            formAsset: "",
            formAchat: "",
            formQuantity: "",
            formExchange: "",
            formVente: "",
            fetchPriceInProgress: false,
            formValid: false,
            availableCoins: []
        }
    },
    mounted: async function(){
        let result = await coinmarketcapApiClient.getMapId()
        this.availableCoins = result.data
    },
    watch: {
        data: async function( newData ){
            if( this.mode == 'sold' && newData.symbol ){
                this.formAsset = newData.coinMarketCapId;
                this.formAchat = newData.prixAchat;
                this.formQuantity = newData.nbCoin;
                this.formExchange = newData.exchange;
                let response = await ActifsApiClient.getCoinData( this.formAsset )
                this.formVente = response.data.quote.USD.price
            }
        }
    },
    methods: {
        add: async function(){
            await OrderApiClient.createBuyOrder( {
               coinMarketCapId : this.formAsset,
               prixAchat       : this.formAchat,
               nbCoin          : this.formQuantity,
               exchange        : this.formExchange
            } )
            this.initField();
            EventBus.$emit('trade-terminated', {reload: true});
        },
        sell: async function(){
            await OrderApiClient.createSellOrder( {
               coinMarketCapId : this.formAsset,
               prixVente       : this.formVente,
               prixAchat       : this.formAchat,
               nbCoin          : this.formQuantity,
               exchange        : this.formExchange
            })
            this.initField();
            EventBus.$emit('trade-terminated', {reload: true});
        }, 
        cancel: function(){
            this.initField();
            EventBus.$emit('trade-terminated', {reload: false});
        },
        initField: function(){
            this.formAsset = "";
            this.formVente = 0;
            this.formAchat = 0;
            this.formQuantity = 0;
            this.formExchang = "";
        },
        fetchCoinPrice: async function(){
            console.log("fetchCoinPrice", this.formAsset)
            if( this.formAsset && this.mode != 'sold'){
                this.fetchPriceInProgress = true;
                let response = await ActifsApiClient.getCoinData( this.formAsset )
                if( response.data.quote ){
                    this.formAchat = response.data.quote.USD.price;
                    this.fetchPriceInProgress = false;
                    this.formValid = true;
                }else{
                    EventBus.$emit('display-error', {
                        type: 'warning',
                        message: "Impossible de trouver le coin " + this.formAsset + " sur CoinMarketCap.",
                        errorCode: "001"
                    })
                    this.fetchPriceInProgress = false;
                    this.formValid = false;
                }
            }
        }
    }
}
</script>
