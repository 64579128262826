<template>
    <div>
        <div v-for="(alert, index) in alerts" :key="index">
            <v-alert 
                :type="alert.type" 
                border="left" 
                :dismissible=dismissibleAlert
                outlined
                text>
                {{alert.message}} (ERR-{{alert.errorCode}})
            </v-alert>
        </div>
    </div>
</template>

<script>
import EventBus from './EventBus'

export default {
    data(){
        return {
            alerts: [],
            dismissibleAlert: true
        }
    },
    mounted: function(){
        EventBus.$on("display-error", this.displayAlert )
    },
    methods: {
        displayAlert: function(alert){
            this.alerts.push( alert )
        }
    }
}

</script>
