<template>
    <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="300px"
    >
      <v-card elevation="5" outlined tile>
        <v-card-text v-if="assets">

                <v-list dense>
                <v-subheader>Choix du token</v-subheader>
                <v-list-item-group
                    color="primary"
                >
                    <v-list-item v-for="asset of assets.data" :key="asset.id">
                        <v-list-item-icon @click="createUsingId(asset.id)">
                            <img :src="'https://s2.coinmarketcap.com/static/img/coins/128x128/'+asset.id+'.png'" />
                        </v-list-item-icon>
                        <v-list-item-content @click="createUsingId(asset.id)">
                            <v-list-item-title v-text="asset.name"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>
                </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </v-row>

</template>

<script>

import ActifApiClient from '../../rest-externals/ActifsApiClient'
import EventBus from '../EventBus'

export default {
    
    props: {
        assets: [],
        showDialog: Boolean
    },
    data() {
        return {
            assets2: [],
            id: null
        }
    },
    methods: {
        createUsingId: async function( id ){
            await ActifApiClient.createActifUsingId(id)
            EventBus.$emit('create-with-id-terminated', {reload: true})
        },
        cancel: function(){
            EventBus.$emit('create-with-id-terminated', {reload: false})
        }
    }
}
</script>
