<template>
  
  <v-row >
    <v-col class="pa-1">
      <FluxMasternodeVue />
    </v-col>
    <v-col class="pa-1">
      <PresearchMasternode />
    </v-col>
    <v-col class="pa-1">
      <StreamrMasternodeVue />
    </v-col>
    <v-col class="pa-1">
      <ThetaMasternodeVue />
    </v-col>
  </v-row>
  
</template>

<script>
import FluxMasternodeVue from './FluxMasternode.vue'
import PresearchMasternode from './PresearchMasternode.vue'
import StreamrMasternodeVue from './StreamrMasternode.vue'
import ThetaMasternodeVue from './ThetaMasternode.vue'


export default {
    name: 'MasternodeController',
    components: {
       FluxMasternodeVue, PresearchMasternode, StreamrMasternodeVue, ThetaMasternodeVue
    },
    data(){
        return {
            user: {}
        }
    },
}
</script>

<style lang="css">


</style>