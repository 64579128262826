import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const AlarmApi = axios.create({
    baseURL: API_URL + '/api/v1/alarm',
    withCredentials: true
    /*headers: { 
        "X-AUTH" : "TODO"
    }*/
});

export default {

    updateAlarm: async function( data ){
        return await AlarmApi.post('/update', data);
    },

}