<template>

  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="300px"
    >
      <v-card elevation="5" outlined tile>
        <v-card-title>
          <span class="text-h5">Nouveau coin</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>

                <v-autocomplete
                  label="Symbole * (Ex. BTC)"
                  :items="availableCoins"
                  item-text="symbol"
                  item-value="id"
                  v-model="selectedCoin">
                  
                  <template v-slot:item="{item}">
                    <v-row>
                      <v-col md="4">
                        <v-img :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/'+item.id+'.png'"  width="30px" />
                      </v-col>
                      <v-col md="8">
                        <span>{{item.name}}</span>
                      </v-col>
                    </v-row>
                  </template>

                </v-autocomplete>

              </v-col>
            </v-row>
          </v-container>
          <small>*Champ obligatoire</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createCoinWithId" :disabled="!selectedCoin">
            Ajouter
          </v-btn>
          <v-btn color="blue darken-1" text @click="cancelCreate">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </v-row>

</template>

<script>
import ActifsApiClient from '../rest-externals/ActifsApiClient';
import EventBus from './EventBus.js'
import coinmarketcapApiClient from '../rest-externals/CoinMarketcapApiClient';

  export default {
    name: 'ActifCreateController',
    data: () => ({
      form: {
        coinSymbol: null,
        
      },
      availableCoins: [],
      sending: false,
      selectedCoin: null
    }),
    props: {
        showDialog: Boolean
    },
    mounted: async function(){
      let result = await coinmarketcapApiClient.getMapId()
      this.availableCoins = result.data
    },
    methods: {
      createCoin: async function() {

        this.sending = true

        if( this.form.coinSymbol ){
            var result = await ActifsApiClient.createActif( this.form.coinSymbol, 0 );
            if( result && result.data && result.data.errorCode && result.data.errorCode != 0){
              if( result.data.multipleResult ){
                EventBus.$emit("display-create-asset-choice", result.data.choice )
              }else{
                EventBus.$emit("display-error", result.data )
              }
            }
        }      
    
        this.sending = false;
        EventBus.$emit('create-terminated', {reload: true});
        this.initForm();
      },
      createCoinWithId: async function(){
        this.sending = true
        
        if( this.selectedCoin ){
            var result = await ActifsApiClient.createActifUsingId( this.selectedCoin );
            if( result && result.data && result.data.errorCode && result.data.errorCode != 0){
                EventBus.$emit("display-error", result.data )
            }
        }      

        this.sending = false;
        EventBus.$emit('create-terminated', {reload: true});
        this.initForm();

      }, 
      cancelCreate: function(){
        EventBus.$emit('create-terminated', {reload: false});
        this.initForm()
      },
      initForm: function(){
        this.form.coinSymbol = null;
        this.selectedCoin = null;
      }
    }
  }
</script>

<style lang="css" scoped>
  .md-progress-bar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
</style>