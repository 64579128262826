<template>
    <div class="content">
        <div class="ico-title">
            <v-icon left dark x-large >
                mdi-bitcoin
            </v-icon>
            <h1>
                Miner stats
            </h1>
        </div>
        <div>
            <a :href="loginUrl">
                <img src="btn_google_signin_light_normal_web.png" alt="login google"/>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            loginUrl: ""
        }
    },
    created(){
        this.loginUrl = "";
        if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
            this.loginUrl = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
        }
        this.loginUrl += '/api/v1/auth/google';
        
    }
}
</script>

<style lang="css" scoped>

.content{
    margin-top: 50vh;
    transform: translateY(-50%);
}

.ico-title{
   display: inline-block;
   color: var(--md-theme-default-text-primary-on-background-variant, #448aff) !important
}

.ico-login{
   
}

</style>