<template>
    <v-row>
        <v-col class="pa-1">
            <v-card outlined tile height="150" min-width="210"  :loading="loading">

                <template slot="progress">
                    <v-progress-linear
                        color="primary"
                        height="5"
                        indeterminate
                        absolute
                    ></v-progress-linear>
                </template>
                
                <v-card-title class="justify-center">
                    Portefeuille total
                </v-card-title>

                <v-card-subtitle class=text-center>
                    Total des actifs
                </v-card-subtitle>

                <v-card-text class="text-center">
                   
                    <div class="total-actif">
                         {{total | toUSD}}
                    </div>
                    <div class="pa-1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
       
                                <v-btn 
                                    icon 
                                    @click="forceReleoad"
                                    v-bind="attrs"
                                    v-on="on"
                                >
        
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>

                            </template>
                            <span>Rafraîchir les côtes</span>
                        </v-tooltip>
                    </div>
                </v-card-text>
            
            </v-card>

        </v-col>

        <v-col class="pa-1">
            <v-card outlined tile height="150" min-width="210" style="overflow: hidden" :loading="loadingFng">
                <template slot="progress">
                    <v-progress-linear
                        color="primary"
                        height="5"
                        indeterminate
                        absolute
                    ></v-progress-linear>
                </template>
                <v-card-title class="justify-center">
                    Fear & Greed
                </v-card-title>
                <v-card-subtitle class="text-center">
                    Statut du marché
                </v-card-subtitle>
                <!--<v-card-text class="justify-center">
                    <v-row v-for="i in fearAndGreed.data" :key="i.timestamp" justify="center" >
                        <v-col class="text-center pa-1" md="8">
                            <span>{{i.value_classification}}</span>
                        </v-col>
                        <v-col class="text-center pa-1">
                            <v-avatar :color="getColor(i)" dense size="25" >
                                <span>
                                    {{i.value}}
                                </span>
                            </v-avatar>
                        </v-col>
                    </v-row>
                </v-card-text>-->
                <v-card-text class="text-center" v-if="fearAndGreed.data">
                     <h1 :class="getFearAndGreedColor(fearAndGreed.data[0])">{{fearAndGreed.data[0].value}}</h1>
                    <v-card-subtitle class=text-center>
                        {{fearAndGreed.data[0].value_classification}}
                    </v-card-subtitle>
                </v-card-text>
            </v-card>

        </v-col>

        <v-col class="pa-1">
            <v-card outlined tile height="150" min-width="210">

                <v-card-title class="justify-center">Rentabilité</v-card-title>
                <v-card-subtitle class=text-center>Retour sur investissement</v-card-subtitle>

                <v-card-text class=text-center>
                    <div class="rentabilite-content">
                        {{ rentabilite.roi | readableRoi}}
                    </div>
                    <v-card-subtitle class="text-center low-padding">
                        <div v-if="rentabilite.nbDayBeforeBreakEven > 0">{{ rentabilite.nbDayBeforeBreakEven | readableRoi }} restant(s)</div>
                        <div v-else style="padding-left=0px;padding-right=0px;">Rentable depuis {{ rentabilite.nbDayBeforeBreakEven | readableRoi }}</div>
                    </v-card-subtitle>
                </v-card-text>
            </v-card>
            </v-col>
        
        <v-col class="pa-1">
            <v-card outlined tile height="150" min-width="210">

                <v-card-title class="justify-center">Evolution</v-card-title>
                <v-card-subtitle class=text-center>Gains moyens journaliers</v-card-subtitle>
                

                <v-card-text class=text-center>
                    <div class="evolution-content">
                        {{evolution.value | readableEvolution}} {{evolution.unit}}
                    </div>
                </v-card-text>
            </v-card>
            
            </v-col>
            <v-col class="pa-1">
            <v-card outlined tile height="150" min-width="210">

                <template slot="progress">
                    <v-progress-linear
                        color="primary"
                        height="5"
                        indeterminate
                    ></v-progress-linear>
                </template>

                <v-card-title class="justify-center">Top gain</v-card-title>
                <v-card-subtitle class=text-center>Crypto gain</v-card-subtitle>

                <v-card-text class=text-center>
                    <div class="gain-content" v-if="topGainer && topGainer.marketData">
                        <div class="gain-ico">
                            <img class="coin-ico" :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/'+topGainer.marketData.id+'.png'" />
                        </div>
                        <div :class="(topGainer.marketData.quote.USD.percent_change_24h > 0 ? 'gain-positif':'gain-negatif') + ' gain-number' ">
                            {{topGainer.marketData.quote.USD.percent_change_24h | toPercentage }} %
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            </v-col>

            <v-col class="pa-1">
                <v-card outlined tile height="150" min-width="210">

                    <v-card-title class="justify-center">Top perte</v-card-title>
                    <v-card-subtitle class=text-center>Crypto Loss</v-card-subtitle>

                    <v-card-text class=text-center>
                        <div class="loss-content" v-if="topLoss && topLoss.marketData">
                            <div class="loss-ico">
                                <img class="coin-ico" :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/'+topLoss.marketData.id+'.png'" />
                            </div>
                            <div :class="(topLoss.marketData.quote.USD.percent_change_24h > 0 ? 'loss-positif':'loss-negatif') + ' loss-number' ">
                                {{topLoss.marketData.quote.USD.percent_change_24h | toPercentage }} %
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

    </v-row>
</template>

<script>

import ActifsApi from '../rest-externals/ActifsApiClient'
import numeral from 'numeral'
import EventBus from './EventBus'
import FearAndGreedApiClient from '../rest-externals/FearAndGreedApiClient'

export default {

    data(){
        return {
            total: 0,
            topGainer: {},
            topLoss: {},
            rentabilite: {},
            evolution: {},
            loading: false,
            fearAndGreed: {},
            loadingFng: false
        }
    },
    created(){
        this.fetchData()
    },
    filters: {
        toUSD: function( number ){
            return numeral(number).format('0,0[.]00 $')
        },
        toPercentage: function( number ){
            return numeral(number).format('0,0[.]00')
        },
        readableRoi: function( number ){
            let result = Math.abs(number)
            if( result > 365 * 2 ){
                if( result > 365 * 4 ){
                    return numeral(result/(365)).format("0[.]00") + " ans"
                }
                return numeral(result/(30)).format("0[.]00") + " mois"
            }
            return  numeral(result).format("0[.]00") + " jours"
        },
        readableEvolution: function( number ){
            return  numeral(number).format("0[.]00")
        }
    },
    mounted: function(){
        EventBus.$on('create-terminated', data => this.hotreload(data) )
        EventBus.$on('update-terminated', data => this.hotreload(data) )
    },
    methods: {
        fetchData: async function(){

            this.loading = true;

            ActifsApi.getTotalAmount().then(
                response => {
                    this.total = response.data.total;
                    this.loading = false;
                }
            );
            
            ActifsApi.getTopGain().then(
                topGainResponse => {
                    this.topGainer = topGainResponse.data;
                }
            );

            ActifsApi.getTopLoss().then(
                topLossResponse => {
                    this.topLoss = topLossResponse.data;
                }
            );
            
            ActifsApi.getRentabilite().then(
                rentabiliteResponse => {
                    this.rentabilite = rentabiliteResponse.data;
                }
            );
            
            ActifsApi.getEvolution().then(
                evolutionResponse => {
                    this.evolution = evolutionResponse.data;
                }
            );
            
            this.loadingFng = true;
            FearAndGreedApiClient.getFearAndGreed().then(
                fearAndGreedResponse => {
                    this.fearAndGreed = fearAndGreedResponse.data;
                    this.loadingFng = false;
                }
            );
            
        },
        hotreload: function( eventData ){
            if( eventData && eventData.reload ){
                this.loading = true;
                this.fetchData()
            }
        },
        forceReleoad: async function(){
            this.loading = true;
            await ActifsApi.refreshCache();
            EventBus.$emit("update-terminated", {reload: true});
        },
        getFearAndGreedColor: function( item ){
            return item.value_classification.replace(" ","_").toLowerCase()
        },
        getColor: function(item){
            if( item.value_classification == 'Extreme Fear' ) return 'red';
            if( item.value_classification == 'Fear' ) return 'orange';
            if( item.value_classification == 'Neutral' ) return 'grey';
            if( item.value_classification == 'Greed' ) return 'greenyellow';
            if( item.value_classification == 'Extreme Greed' ) return 'green';
        }
    }
}
</script>

<style lang="postcss" scoped>

.total-actif, .rentabilite-content, .evolution-content{
    color: orange;
    font-weight: bold;
    font-size: 25px;
}

.gain-number, .loss-number{
    vertical-align: middle;
}

.gain-ico, .loss-ico{
    vertical-align: middle;
    margin-right: 10px;
    display: inline;
    width: 25px;
}

.gain-positif, .loss-positif{
    color: green;
    font-weight: bold;
    font-size: 25px;
    display: inline;
}

.gain-negatif, .loss-negatif{
    color: red;
    font-weight: bold;
    font-size: 25px;
    display: inline;
}

.coin-ico{
    width: 25px;
}

.white-ico{
    color:white;
}

.forceReload{
    font-size: 15px;
}

.extreme_fear{
    color: red;
}

.fear{
    color: orange;
}

.neutral{
    color: grey;
}

.greed{
    color: greenyellow;
}

.extreme_greed{
    color: green;
}

.low-padding{
    padding-left:5px;
    padding-right:5px;
}

</style>
