import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const OrderApiClient = axios.create({
    baseURL: API_URL + '/api/v1/order',
    withCredentials: true
});

export default {

    getAllOrder: async function(){
        return await OrderApiClient.get('/list');
    },

    createSellOrder: async function( order ){
        return await OrderApiClient.post( '/sell', {
            'order': order
        });
    },

    createBuyOrder: async function( order ){
        return await OrderApiClient.post( '/buy', {
            'order': order
        });
    },

    deleteOrder: async function( order ){
        return await OrderApiClient.delete( '/delete', { data: order } )
    },

}