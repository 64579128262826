<template>
    <v-card tile outlined height="498" dense>
        <v-card-title >
                <v-btn class="mx-2" fab dark color="indigo" @click="addCoin" small>
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
            <span class="clickable">Coins</span>
        </v-card-title>
        <v-card-text>
            <v-simple-table height="424">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-right d-none d-sm-table-cell">
                            </th>
                            <th class="text-right">
                                Symbole
                            </th>
                            <th class="text-right">
                                Nombre de coin
                            </th>
                            <th class="text-right d-none d-sm-table-cell">
                                Valeur
                            </th>
                            <th class="text-right d-none d-sm-table-cell">
                                Evolution
                            </th>
                            <th>
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in coins" :key="item.id" class="text-right">
                            <td class="d-none d-sm-table-cell" v-if="item.marketData">
                                <img class="coin-ico md-image" :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/'+item.marketData.id+'.png'" />
                            </td>
                            <td>
                                {{ item.symbol }}
                            </td>
                            <td v-if="item.marketData">
                                <div class="perform-update">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-container v-on="on" v-bind="attrs" @click="updateActif(item)">{{ item.nbCoin | limitNumber }}</v-container>
                                        </template>
                                        <span v-if="item.nbCoin>0">Mettre à jour {{item.symbol}}</span>
                                        <span v-else>Ajouter des {{item.symbol}}</span>
                                    </v-tooltip>
                                </div>
                            </td>
                            <td class="d-none d-sm-table-cell" v-if="item.marketData">
                                {{ item.marketData.quote.USD.price | toUSD }}</td>
                            <td class="d-none d-sm-table-cell" v-if="item.marketData">
                                <div :class="(item.marketData.quote.USD.percent_change_24h > 0 ? 'gain-positif':'gain-negatif')">
                                    {{ item.marketData.quote.USD.percent_change_24h | toPercentage }}
                                </div>
                            </td>
                            <td>
                                {{ item.total | toUSD }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <ActifUpdateController :actif="coinToUpdate" :active="showUpdateActif" />
        <ActifCreateController :showDialog="showCreateActif" @create-terminated="hidePopup" />
        <CreateAssertChoicePopup :showDialog="showDialogChoixCreateAssert" :assets="assetsChoice" />
    </v-card>

</template>

<script>
import numeral from 'numeral'
import ActifUpdateController from './ActifUpdateController.vue'
import ActifsApiClient from '../rest-externals/ActifsApiClient'
import ActifCreateController from './ActifCreateController.vue'
import EventBus from './EventBus'
import CreateAssertChoicePopup from './wallet/AssetChoicePopup.vue'

export default {
    data(){
        return {
            coins: [],
            coinToUpdate: {},
            showUpdateActif: false,
            showCreateActif: false,
            showDialogChoixCreateAssert: false,
            assetsChoice: []
        }
    },
    components: {
        ActifUpdateController, ActifCreateController, CreateAssertChoicePopup
    },
    created: async function(){
        this.fetchActif();
    },
    filters: {
        toUSD: function( number ){
            return numeral(number).format('0,0[.]00 $')
        },
        toPercentage: function( number ){
            return numeral(number).format('0,0[.]00') + " %"
        },
        limitNumber: function( number ){
            return numeral(number).format('0,0[.]00000')
        }
    },
    mounted: function(){
        EventBus.$on('create-terminated', d => this.hidePopup(d) )
        EventBus.$on('update-terminated', d => this.hidePopup(d) )
        EventBus.$on('display-create-asset-choice', d=> this.showChoiceCreateAsset(d) )
        EventBus.$on('create-with-id-terminated', d => this.hideChoicePopup(d))
    },
    methods: {

        fetchActif: async function(){
            ActifsApiClient.getAllActifs().then(
                response => { 
                    this.coins = response.data.actifs
                    if( this.coins && this.coins.length > 0 ){
                        this.coins.sort( function(a, b){
                            return b.total - a.total;
                        });
                    }
                    let errors = response.data.errors;
                    for( let error of errors ){
                        EventBus.$emit("display-error", {
                                type: "warning",
                                errorCode: "001",
                                message: error.message})
                    }
                },
                error => {console.log(error)}
            );           
            
        },

        updateActif: function(item){
            this.showUpdateActif = true;
            this.coinToUpdate = item;
        },

        hidePopup: function(data){
           
           this.showUpdateActif = false;
           this.showCreateActif = false;
           

           if(data && data.reload){
               this.fetchActif()
           }

        },

        addCoin: function(){
            this.showCreateActif = true;
        },
        showChoiceCreateAsset: function( data ){
            this.showDialogChoixCreateAssert = true
            this.assetsChoice = data
        },
        hideChoicePopup: function( reload ){
            this.showDialogChoixCreateAssert = false;
            if( reload ){
                this.fetchActif()
            }
        }

    }
}
</script>

<style lang="css" scoped>
.md-table .md-table-cell.md-has-action{
  justify-content: space-around;
}

.md-table .md-table-head {
  text-align: center;
}

.md-table .md-table-cell {
  text-align: center;
}

.coins {
    margin: auto;
}

.coin-ico {
    width: 25px !important;
}

.perform-update:hover{
    cursor: pointer;
    color: var(--md-theme-default-text-primary-on-background-variant, #448aff);
    text-decoration: underline;
}

.add-coin:hover{
    cursor: pointer;
    color: var(--md-theme-default-text-primary-on-background-variant, #448aff) !important;
    text-decoration: underline;
}

</style>

<style lang="css" scoped>
.gain-positif{
    color: green;
}

.gain-negatif{
    color: red;
}
</style>