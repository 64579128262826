import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,_vm._l((_vm.rigs),function(rig){return _c(VCard,{key:rig.id,attrs:{"outlined":"","tile":""}},[_c(VCardTitle,[_vm._v(_vm._s(rig.name))]),_c(VCardText,_vm._l((rig.gpus),function(gpu){return _c(VRow,{key:gpu.id,attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"1"}},[_vm._v("GPU "+_vm._s(gpu.number))]),(gpu && gpu.name)?_c(VCol,{attrs:{"cols":"1"}},[_vm._v(_vm._s(gpu.name.replace("GeForce","")))]):_vm._e(),_c(VCol,[_c(VTextField,{attrs:{"label":"fanMax","require":"","type":"text"},model:{value:(gpu.alarms.fanMax),callback:function ($$v) {_vm.$set(gpu.alarms, "fanMax", $$v)},expression:"gpu.alarms.fanMax"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Température max"},model:{value:(gpu.alarms.tempMax),callback:function ($$v) {_vm.$set(gpu.alarms, "tempMax", $$v)},expression:"gpu.alarms.tempMax"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":"Hashrate min"},model:{value:(gpu.alarms.minHashrate),callback:function ($$v) {_vm.$set(gpu.alarms, "minHashrate", $$v)},expression:"gpu.alarms.minHashrate"}})],1),_c(VCol,[_c(VBtn,{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveAlarm(gpu)}}},[_vm._v(" Sauvegarder ")])],1)],1)}),1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }