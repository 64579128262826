import Vue from 'vue'
import App from './App.vue'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default-dark.css'

import moment from 'moment';
import VueMoment from 'vue-moment';

import crono from 'vue-crono';

import LoginController from './components/LoginController.vue';
import MainController from './components/MainController.vue';
import VueRouter from 'vue-router';
import TradeController from './components/trade/TradeController.vue';
import UserController from './components/user/UserController.vue';
import AlarmController from './components/miner/AlarmController'
import AboutController from './components/AboutController.vue'

const routes = [
    {
      path: '/login', name: 'login', component: LoginController      
    },{
      path: '/', name: 'main', component: MainController
    },{
      path:'/trade', name: 'trade', component: TradeController
    },{
      path:'/user', name: 'user', component: UserController
    },{
      path:'/alarms', name: 'alarms', component: AlarmController
    },{
      path:'/about', name: 'about', component: AboutController
    },
]
const router = new VueRouter({
  routes // short for `routes: routes`
})

// Load Locales ('en' comes loaded by default)
require('moment/locale/fr');
moment.locale('fr');

Vue.use(VueMoment, { moment });
Vue.use(VueRouter);
Vue.use(crono);

import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.use(VueMaterial)

Vue.config.productionTip = false
Vue.use(VueMaterial)
Vue.use( HighchartsVue )

Highcharts.setOptions({
  lang: {
    noData: "New message, when the chart is empty.",
    months: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
    shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Jui", "Aou", "Sep", "Oct", "Nov", "Déc"]
  }
});

Vue.use(VueMoment, { moment });

new Vue({
  render: h => h(App),
  vuetify,
  router
}).$mount('#app')
