<template>
  <v-app>

    <Toolbar :title="'Miner stats'" :user="user"/>

    <v-main>

       <v-container fluid>
        <router-view></router-view>
      </v-container>

    </v-main>

    <v-footer app v-if="user" class="pa-0 pm-0" align="center">
      <FooterController />
    </v-footer>

  </v-app>
</template>

<script>
import Toolbar from './components/ToolbarComponent.vue'
import UserRestApi from './rest-externals/UserActionsApiClient';
import FooterController from './components/FooterController.vue';

export default {
  name: 'App',

  components: {
    Toolbar, FooterController
  },

  data: () => ({
    user: undefined
  }),
  mounted: async function(){
    if( this.$route.name != "login" ){
      try{
        var response = await UserRestApi.getLoggedUser();
        this.user = response.data;
        if( this.user == undefined || this.user.id == undefined ){
          this.$router.push('/login')
          this.$router.go('login')
        }
      }catch( error ){
        if( error.response.status === 401 ){
          this.$router.push('/login')
          this.$router.go('login')
        }
      }
    }
  }
};
</script>

<style lang="css">

::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

</style>
