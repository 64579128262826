import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const RigsApi = axios.create({
    baseURL: API_URL + '/api/v1/rig',
    withCredentials: true
    /*headers: { 
        "X-AUTH" : "TODO"
    }*/
});

export default {

    getRigState: async function(){
        return await RigsApi.get('/all');
    },

    getRigsSummary: async function(){
        return await RigsApi.get('/summary');
    }

}