import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const MinerStatsApi = axios.create({
    baseURL: API_URL + '/api/v1/wallet',
    withCredentials: true
    /*headers: { 
        "X-AUTH" : "TODO"
    }*/
});

export default {

    createOrUpdateList: async function( listCoin ){
        return await MinerStatsApi.post('/create/list', listCoin );
    },

}