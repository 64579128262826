<template>

  <v-card outlined tile height="200" min-width="210" :loading="loading < 2">
    <v-card-title>
       Masternode presearch
    </v-card-title>
    <v-card-text>
        <div>
            Benchmark : <br />
            ip : <br />
            Noeud : <br />
            Status : <br />
            Espace libre :<br />
        </div>
    </v-card-text>
  </v-card>
  
</template>

<script>

import numeral from 'numeral'


export default {

    data: function(){
        return {
            loading: 0,
            wallet: {},
            trades: []
        }
    },

    filters: {
        toDate: function( timestamp ){
            return new Date( timestamp/1000000 ).toLocaleString()
        },
        toUSD: function( number ){
            return numeral(number).format('0[.]00')
        }
    },

    mounted: async function(){
     
    },

    methods: {
        
    }
}
</script>

<style lang="css"  scoped>

.coins {
    margin: auto;
}

.coin-ico {
    width: 25px !important;
}

.gain-positif, .loss-positif{
    color: green;
}

.gain-negatif, .loss-negatif{
    color: red;
}

</style>