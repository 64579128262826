<template>
    <v-container>
        <v-card v-for="rig of rigs" :key="rig.id" outlined tile >
            <v-card-title>{{rig.name}}</v-card-title>
            <v-card-text>
                <v-row v-for="gpu of rig.gpus" :key="gpu.id" align="center" justify="center">
                    <v-col cols="1">GPU {{gpu.number}}</v-col>
                    <v-col cols="1" v-if="gpu && gpu.name">{{gpu.name.replace("GeForce","")}}</v-col>
                    <v-col >
                        <v-text-field label="fanMax" require type="text" v-model="gpu.alarms.fanMax"/>
                    </v-col>
                    <v-col>
                        <v-text-field label="Température max" v-model="gpu.alarms.tempMax"/>
                    </v-col>
                    <v-col>
                        <v-text-field label="Hashrate min" v-model="gpu.alarms.minHashrate"/>
                    </v-col>
                    <v-col>
                        <v-btn type="primary" @click="saveAlarm(gpu)">
                            Sauvegarder
                        </v-btn>
                    </v-col>
                </v-row>    
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import RigApiClient from '../../rest-externals/RigApiClient'
import AlarmApiClient from '../../rest-externals/AlarmApiClient'
import Vue from 'vue'

export default {
    mounted: async function(){
        this.fetchData()
    },
    data: function(){
        return {
            rigs: []
        }
    },
    methods: {
        fetchData: async function(){
            let response = await RigApiClient.getRigState();
            response.data.forEach( rig => {
                rig.gpus.forEach( gpu => {
                    if( !gpu.alarms ){
                        gpu.alarms = {}
                    }
                } )
            } )
            this.rigs = response.data
        },
        saveAlarm: function( gpu ){
            let result = AlarmApiClient.updateAlarm( gpu )
            let alarms = result.data
            gpu.alarms = alarms
            for( var i = 0 ; i < this.rigs.length; i++){
                let rig = this.rigs[i]
                rig.gpus.forEach( rgpu => {
                    if( rgpu.name == gpu.name ){
                        gpu.alarms = alarms
                        Vue.set( this.rigs, i, rig )
                    }
                } )
            }
        },
    }
}
</script>
