import axios from 'axios' 

var API_URL="";
if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
    API_URL = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
}
 
const UserParamApi = axios.create({
    baseURL: API_URL + '/api/v1/param',
    withCredentials: true
    /*headers: { 
        "X-AUTH" : "TODO"
    }*/
});

export default {

    getAll: async function(){
        return await UserParamApi.get('/all/');
    },

     createBinance : async function(param){
        return await UserParamApi.post('/create/binance/', param);
    },
    
     createKucoin : async function(param){
        return await UserParamApi.post('/create/kucoin/', param);
    },
    
     createFlux : async function(param){
        return await UserParamApi.post('/create/flux/', param);
    },
    
     createBot : async function(param){
        return await UserParamApi.post('/create/bot/', param);
    },
    
     deleteBinance : async function(){
        return await UserParamApi.post('/delete/binance/');
    },
    
     deleteKucoin : async function(){
        return await UserParamApi.post('/delete/kucoin/');
    },
    
     deleteFlux : async function(){
        return await UserParamApi.post('/delete/flux/');
    },
    
     deleteBot : async function(){
        return await UserParamApi.post('/delete/bot/');
    },



}