<template>
    <v-card class="mx-auto overflow-hidden" v-if="user">
        <v-app-bar dark app>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-toolbar-title>Miner gate</v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            app
            temporary
            icon="https://s2.coinmarketcap.com/static/img/coins/128x128/1027.png"
        >
        <v-list>
            <v-list-item class="px-2">
            <v-list-item-avatar>
                <v-img :src="user.image"></v-img>
            </v-list-item-avatar>
            </v-list-item>

            <v-list-item link to="user" style="text-decoration: none; color: inherit;">
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        {{user.displayName}}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :href="logout" style="text-decoration: none; color: inherit;">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Déconnexion</v-list-item-title>
            </v-list-item>
        </v-list>

            <v-divider></v-divider>

            <v-list nav dense>
                
                <v-list-item link to="/" style="text-decoration: none; color: inherit;">
                    <v-list-item-icon>
                    <v-icon>mdi-wallet-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Mon portefeuille</v-list-item-title>
                </v-list-item>

                <v-list-item link to="trade" style="text-decoration: none; color: inherit;" v-if="isPremiumUser('USER_PREMIUM')">
                    <v-list-item-icon>
                    <v-icon>mdi-currency-usd</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Mes trades</v-list-item-title>
                </v-list-item>

                <v-list-item link to="alarms" style="text-decoration: none; color: inherit;" v-if="isPremiumUser('USER_MINER')">
                    <v-list-item-icon>
                    <v-icon>mdi-alarm-light</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Alarmes</v-list-item-title>
                </v-list-item>

                <v-list-item link to="about" style="text-decoration: none; color: inherit;">
                    <v-list-item-icon>
                    <v-icon>mdi-notebook-edit-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Guide utilisateur</v-list-item-title>
                </v-list-item>

            </v-list>

        </v-navigation-drawer>

    </v-card>

</template>

<script>

export default {
    data() {
        return {
           displayedUser:{},
           logout: "",
           drawer: false,
           group: null
        }
    },
    props: {
        title: String,
        user: Object
    },
    created(){
        this.logout = "";
        if( process.env.VUE_APP_API_URL && process.env.VUE_APP_API_PORT ){
            this.logout = 'http://' + process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT;
        }
        this.logout += '/api/v1/auth/logout/';        
    },
    methods : {
        isPremiumUser : function(specificRole){

            let isPremium = false;
            let hasSpecificRole = false;

            if(this.user != null && this.user.roles != null){
                this.user.roles.forEach(element => {
                if( element === 'USER_PREMIUM' ){
                    isPremium = true
                }
                if( specificRole && element === specificRole ){
                    hasSpecificRole = true
                }
                });
                if( specificRole ){
                return isPremium && hasSpecificRole
                }
            }  
            return isPremium;
        },
    }

}
</script>

<style lang="css" scoped>

.app-toolbar .miner-stat-toolbar-title {
    color: var(--md-theme-default-text-primary-on-background-variant, #448aff) !important;
    text-align: left;
    font-weight: bold;
}

.app-toolbar{
    margin-bottom: 20px;
}

.app-toolbar-ico{
    width: 50px;
    padding: 10px;
}

</style>
