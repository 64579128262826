<template>

    <v-card outlined tile height="400" min-width="210" :loading="loading < 2">
      <v-card-title>
          Bot sur compte de futur
      </v-card-title>
      <v-card-text>
  
          <div v-if="wallet.name">  
              {{wallet.name}} : {{wallet.amount | toUSD }} $
          </div>
          <div v-else>  
             Chargement en cours
          </div>
          <div v-if="getNbPosition() == 0">
            Aucune position en cours
          </div>
          <div v-if="getNbPosition() > 0">
            Trade en cours : {{this.wallet.positions[0].avgEntryPrice | toUSD}} / {{this.wallet.positions[0].unrealisedPnl | toUSD}} / {{this.wallet.positions[0].realLeverage }} / {{this.wallet.positions[0].liquidationPrice }}
          </div>
          
          <v-simple-table height="290">
              <template v-slot:default>
                  <thead>
                      <tr>
                          <th class="text-center d-none d-sm-table-cell">
                          </th>
                          <th class="text-center">
                              Date
                          </th>
                          <th class="text-center d-none d-sm-table-cell">
                              Asset
                          </th>
                          <th class="text-center d-none d-sm-table-cell">
                              Sens
                          </th>
                          <th class="text-center">
                              Taille
                          </th>
                          <th class="text-center d-none d-sm-table-cell">
                              Frais
                          </th>
                          <th class="text-center">
                              Prix
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(trade, i) in trades" :key="i" class="text-center">
                          <td class="d-none d-sm-table-cell" v-if="trade.marketData">
                              <img class="coin-ico md-image" :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/'+trade.marketData.id+'.png'" />
                          </td>
                          <td>
                              {{ trade.ts | toDate }}
                          </td>
                          <td class="d-none d-sm-table-cell">
                              {{ trade.asset }}
                          </td>
                          <td class="d-none d-sm-table-cell">
                              <div :class=" trade.sens == 'sell' ? 'gain-negatif':'gain-positif' ">
                                {{ trade.sens }}
                              </div>
                          </td>
                          <td :class=" trade.sens == 'sell' ? 'gain-negatif':'gain-positif' ">
                              {{ trade.size }}
                          </td>
                          <td class="d-none d-sm-table-cell">
                              {{ trade.fee | toUSD }}
                          </td>
                          <td>
                              {{ trade.avgPrice }}
                          </td>
                      </tr>
                  </tbody>
              </template>
          </v-simple-table>
      </v-card-text>
    </v-card>
    
  </template>
  
  <script>
  
  import tradingBotApiClient from '../../rest-externals/trades/TradeApiClient';
  import numeral from 'numeral'
  
  export default {
  
      data: function(){
          return {
              loading: 0,
              wallet: {},
              trades: []
          }
      },
  
      filters: {
          toDate: function( timestamp ){
              return new Date( timestamp/1000000 ).toLocaleString()
          },
          toUSD: function( number ){
              return numeral(number).format('0[.]00')
          }
      },
  
      mounted: async function(){
       this.loading = 0;
       this.fetchFuturHistory();
       await this.fetchFuturWallet();
      },
  
      methods: {
          fetchFuturWallet: async function(){
              let result = await tradingBotApiClient.getTradingAccount();
              this.wallet = result?.data
              this.loading++
          },
          fetchFuturHistory: async function(){
              let result = await tradingBotApiClient.getTradingHistory();
              this.trades = result?.data
              this.loading++
          },
          getNbPosition(){
            if( this.wallet && this.wallet.positions ){
              return this.wallet.positions.length;
            }
            return 0;
          }
      }
  }
  </script>
  
  <style lang="css"  scoped>
  
  .coins {
      margin: auto;
  }
  
  .coin-ico {
      width: 25px !important;
  }
  
  .gain-positif, .loss-positif{
      color: green;
  }
  
  .gain-negatif, .loss-negatif{
      color: red;
  }
  
  </style>