<template>
    <div>

         <v-card>
            <v-card-title>Historique de trading</v-card-title>
            <v-card-text>
                <v-expansion-panels multiple v-for="(group, i) of itemsGroups" :key="i">
                        <ItemTradeController :items="group" :index="i" @sold="sold" @delete="deleteItem"/>

                </v-expansion-panels>
            </v-card-text>
        </v-card>


            <v-card-actions class="justify-center">
                <v-btn color="success" @click="showPopup=true">Ajouter trade</v-btn>
            </v-card-actions>
            <TradePopupController :showPopup="showPopup" :mode="mode" :data="itemToSold" />

       

    </div>
</template>

<script>

import OrderApiClient from '../../rest-externals/order/OrderApiClient'
import EventBus from '../EventBus.js'
import TradePopupController from './TradePopup.controller.vue';
import numeral from 'numeral'
import ItemTradeController from './ItemTradeController.vue';

export default {
    components: {
        TradePopupController, ItemTradeController
    },
    data: function(){
        return {
            showPopup: false,
            expanded:  [],
            orders: [],
            headers: [
                { text: 'Trades', align: 'start', value: 'exchange', groupable: false, },
                { text: 'Symbol', value: 'marketData.name', align: 'right' , groupable: false},
                { text: 'Ordre', value: 'typeOrdre', align: 'right' , groupable: false},
                { text: "Prix d'achat", value: 'prixAchat', align: 'right', groupable: false },
                { text: "Prix de vente", value: 'prixVente', align: 'right', groupable: false },
                { text: "Quantite", value: 'nbCoin', align: 'right', groupable: false },
                { text: "PNL", value: 'total' , align: 'right', groupable: false },
                { text: "Resultat", value: 'resultat' , align: 'right', groupable: false },
                { text: "Actions", value: 'action' , align: 'right', groupable: false },
            ],
            formAsset: "",
            formAchat: "",
            formQuantity: "",
            formExchange: "",
            formCoinMarketCapId: "",
            mode:"add",
            itemToSold: {},
            itemsGroups: {}
        }
    },
    filters: {
        toUSD: function( number ){
            return numeral(number).format('0,0[.]00 $')
        },
        toPercentage: function( number ){
            return numeral(number).format('0,0[.]00') + " %"
        },
        limitNumber: function( number ){
            return numeral(number).format('0,0[.]00000')
        }
    },
    mounted: async function(){
        this.fetchData();
        EventBus.$on('trade-terminated', this.reloadData )
    },
    methods: {
        fetchData: async function(){
            let response = await OrderApiClient.getAllOrder();
            this.orders = response.data;
            this.itemsGroups = {};
            for( let order of this.orders){
                if( !this.itemsGroups[ order.symbol ] ){
                    this.itemsGroups[ order.symbol ] = []
                }
                this.itemsGroups[ order.symbol ].push(order)
            }
            //this.$set(this.itemsGroups, 'end', 1)
        },
        buy: async function(){
            await OrderApiClient.createBuyOrder( {
               symbol     : this.formAsset,
               prixAchat : this.formAchat,
               nbCoin     : this.formQuantity,
               exchange   : this.formExchange,
               coinMarketCapId: this.coinMarketCapId
            } )
            await this.fetchData()
        },
        sell: async function(){
            await OrderApiClient.createSellOrder( {
               symbol     : this.formAsset,
               prixVente  : this.formAchat,
               nbCoin     : this.formQuantity,
               exchange   : this.formExchange
            } )
            await this.fetchData()
        },
        compute: function(items){
            var achatResultat = 0;
            for( const item of items ){

                //pour les achats, on compare le prix d'achat au prix actuel : 
                if( item.prixAchat > 0 && !item.prixVente ){
                    //console.log('compute', item)
                    achatResultat += (item.nbCoin * item.marketData.quote.USD.price) - (item.nbCoin * item.prixAchat)
                }

                //pour les ventes :
                else{
                    achatResultat += ( item.nbCoin * item.prixVente ) - (item.nbCoin * item.prixAchat)
                }
            }

            return achatResultat;
        },
        getImage: function( group ){
            for( const order of this.orders ){
                if( order.marketData.name == group ){
                    return order.marketData.id
                }
            }
            return null;
        },
        computeMoyennePondereeAchat: function(items){
            let dividende = 0;
            let diviseur  = 0;
            for(const item of items){
                if( item.prixAchat > 0 && item.prixVente <= 0){
                    dividende = dividende + ( item.prixAchat * item.nbCoin );
                    diviseur += item.nbCoin;
                }
            }
            return dividende/diviseur;
        },
        computeMoyennePondereeVente: function(items){
            let dividende = 0;
            let diviseur  = 0;
            for(const item of items){
                if( item.prixVente > 0 ){
                    dividende = dividende + ( item.prixVente * item.nbCoin );
                    diviseur += item.nbCoin;
                }
            }
            if( diviseur == 0 ){
                return 0;
            }
            //console.log(items[0].marketData.name + ':' + dividende +'/'+diviseur);
            return dividende/diviseur;
        },
        computeQuantity: function(items){
            let quantity = 0;
            for(const item of items){
                if( item.prixVente > 0 ){
                    quantity -= item.nbCoin;
                }else{
                    quantity += item.nbCoin;
                }
            }
            return quantity;
        },
        computeTotalAmount: function(items){
            let total = 0;
            for(const item of items){
                if( item.prixAchat > 0 && !item.prixVente ){
                    total += item.prixAchat * item.nbCoin;
                }
            }
            return total;
        },
        deleteItem: async function(item){
            await OrderApiClient.deleteOrder(item);
            this.fetchData()
        },
        shouldCollapse: function( group, toggle ){
            
            if( !this.expanded[ group ] ){
                this.expanded[group] = true
                toggle()
            }

        },
        reloadData: function( data ){
            if( data.reload ){
                this.fetchData();
            }
            this.showPopup = false;
            this.mode = 'add';
            this.itemToSold = {}
        },
        sold: async function( data ){
            this.mode = 'sold';
            this.itemToSold = data;
            this.showPopup=true
        }
    }
}
</script>

<style scoped>

.subtitle{
    font-style: italic;
    font-size: 11px;
}

.gain-positif{
    color: green;
}

.gain-negatif{
    color: red;
}

</style>